const styles = {
  homeSection: "pt-8 md:pt-6 lg:pt-12",
  homeContainer: "px-6 md:px-8 xl:px-6 mb-12",
  homeContent: "flex flex-col  md:flex-row lg:gap-4",
  homeTextContainer:
    "md:w-1/2 md:mx-4 lg:ml-8 flex flex-col justify-center items-center",
  homeTextContent: " flex flex-col gap-4 lg:gap-12 items-start justify-center ",
  homeTitle:
    "text-3xl font-bold sm:text-4xl md:text-3xl xl:text-4xl text-black dark:text-white",
  homeTitleText1: "text-thirdGreen dark:text-secondaryGreen",
  homeTitleText2: "text-defaultYellow",
  paragraphsContainer:
    "leading-6 md:leading-0 sm:text-sm md:text-base lg:text-lg xl:text-xl flex flex-col gap-3 md:gap-5 text-black dark:text-white font-sans font-semibold ",
  specialText: "text-defaulBlack dark:text-white font-bold font-inter text-xl",
  homeImagesContainer:
    "md:w-1/2 flex flex-row gap-6 pt-8  lg:p-0 items-center justify-center",
  homeImagesContent1: "w-1/3 flex flex-col gap-6 items-center justify-center",
  homeImagesContent2: "w-1/2 flex flex-col gap-4 items-center justify-center",
  homePhones:
    "hover:scale-110 duration-700 w-full h-auto self-center rounded-xl ",
  homeButtons: "w-full my-4  flex justify-start items-center gap-14",
  btnContainer: "block",
  androidBtn: "w-40 max-h-14 md:max-h-16",
  iosBtn: "w-40 max-h-14 md:max-h-16",
};
export default styles;
