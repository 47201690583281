import { useAppContext } from '../../context';
import errorDark from '../../assets/error_dark.svg';
import errorLight from '../../assets/error_light.svg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import homeIcon from '../../assets/homeIcon.svg';
import styles from './styles';

function ErrorPage() {
  const { isDarkMode } = useAppContext();
  const { t } = useTranslation();
  return (
    <div
      className={`${
        isDarkMode ? styles.darkContainer : styles.whiteContainer
      } ${styles.container}`}
    >
      <div >
        <img
          src={isDarkMode ? errorDark : errorLight}
          alt='error-illustration'
        />
        <div className={styles.content}>
          <h1 className={styles.heading}>{t('errorPage.heading')}</h1>
          <p className={styles.text}>{t('errorPage.text')}</p>
        </div>
      </div>
      <div className={styles.linkContainer}>
        <Link to={`/`} className={styles.link}>
          <p>{t('errorPage.buttonText')}</p>
          <img src={homeIcon} alt='home-icon' className={styles.homeIcon} />
        </Link>
      </div>
    </div>
  );
}

export default ErrorPage;
