import Logo from "../logo";
import styles from "./styles";
import NavItems from "../navItems";
import { useLocation } from "react-router-dom";
import GetStarted from "../getStarted";

const NavBar = () => {
  const location = useLocation();
  return (
    <header id="home">
      <nav
        className={`${styles.navContainer} ${location.pathname.includes("help-center")
            ? styles.fixed
            : styles.static
          }`}
      >
        <div
          className={
            location.pathname.includes("/help-center")
              ? "w-full mx-2 flex flex-wrap justify-between items-center"
              : styles.navItems
          }
        >
          <Logo />
          <NavItems />
          <div
            className={
              location.pathname.includes("/help-center") ||
                location.pathname.includes("/privacy-policy") ||
                location.pathname.includes("/terms-conditions")
                ? "hidden"
                : "hidden lg:block"
            }
          >
            <GetStarted />
          </div>
        </div>
      </nav>
    </header>
  );
};

export default NavBar;
