import React from "react";
import USSD from "../../assets/USSD.png";
import { useTranslation } from "react-i18next";
import styles from "./styles";
function Ussd() {
  const { t } = useTranslation();
  return (
    <section className={styles.homeSection}>
      <div className="bg-[rgb(0,73,69)] text-white p-4 md:p-8 w-full flex flex-col gap-2 md:flex-row md:gap-8">
        <div className="w-full md:w-3/5 flex gap-8 flex-col">
          <h2 className="font-semibold text-2xl  ">
            {t("homePage.ussdTitle")}
          </h2>
          <p className="lg:leading-8">{t("homePage.paragraph2")}</p>
        </div>
        <div className=" w-[30%] lg:w-[25%] xl:w-1/5 md:h-[250px] lg:h-[200px] flex mx-auto items-center justify-center ">
          <span className="">
            <img src={USSD} alt="ussd" className="" />
          </span>
        </div>
      </div>
    </section>
  );
}

export default Ussd;
