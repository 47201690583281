import rules from '../../../assets/rules.png'
import { useTranslation } from 'react-i18next'
import FeedBack from '../../../components/feedback'

function ChangeRules() {
  const { t } = useTranslation()

  return (
    <article>
      <h1 className='text-xl md:text-2xl font-bold dark:text-secondaryGreen'>
        {t('helpCenter.sidebar.How do I change group rules?')}
      </h1>
      <div className='w-full h-[1px] bg-[#eff2f5] dark:bg-[#303032] mt-4 '></div>
      <section className='mt-8'>
        <p className='mb-2'>
          <span className='font-semibold'>Step 1: </span>
          {t('helpCenter.Ikimina.changeRules.step1')}
        </p>
        <p className='mb-2'>
          <span className='font-semibold'>Step 2: </span>
          {t('helpCenter.Ikimina.changeRules.step2.part1')}
          <img src={rules} alt='icon' className='inline-flex w-32' />
          {t('helpCenter.Ikimina.changeRules.step2.part2')}
        </p>
        <p className='mb-2'>
          <span className='font-semibold'>Step 3: </span>
          {t('helpCenter.Ikimina.changeRules.step3.part1')}
          {t('helpCenter.Ikimina.changeRules.step3.part2')}
        </p>
        <p className='mb-2'>
          <span className='font-semibold'>Step 4: </span>
          {t('helpCenter.Ikimina.changeRules.step4')}
        </p>
      </section>
      <FeedBack />
    </article>
  )
}

export default ChangeRules
