import { useState, useEffect } from 'react'
import styles from './styles'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import createURL from '../../helpers/joinURLString'
import { allEnQuestions, allRwQuestions } from '../../constants/tableOfContents'
import { ReactComponent as SearchIcon } from '../../assets/searchIcon.svg'
import Footer from '../../pages/footer'
import { useAppContext } from '../../context'

const Search = () => {
  const { t } = useTranslation()
  const [searchWord, setSearchWord] = useState('')
  const [isSearching, setIsSearching] = useState(false)
  const [filterResult, setFilterResult] = useState([])
  const { language } = useAppContext()
  const allQuestions = language === 'en' ? allEnQuestions : allRwQuestions

  useEffect(() => {
    const id = setTimeout(() => {
      setIsSearching(!!searchWord.length)
      const questions = allQuestions
        .filter((question) =>
          question.toLowerCase().includes(searchWord.toLowerCase())
        )
        .slice(0, 6)
      if (questions.length) {
        setFilterResult(questions)
      } else {
        SearchUnknown()
      }
    }, 500)

    const SearchUnknown = () => {
      let keywords = searchWord.split(' ')
      let query = []
      keywords.forEach((keyword) => {
        query = query
          .concat(
            allQuestions.filter((q) =>
              q.toLowerCase().includes(keyword.toLowerCase())
            )
          )
          .slice(0, 6)
      })
      setFilterResult(query)
    }

    return () => {
      clearTimeout(id)
    }
  }, [searchWord, allQuestions])

  const showResults = isSearching && searchWord

  return (
    <>
      <div className={styles.container}>
        <div className={styles.inputContainer}>
          <div className={`${styles.inputField}`}>
            <div className={styles.searchIconContainer}>
              <SearchIcon className={styles.searchIcon} />
            </div>
            <input
              type='text'
              className={styles.input}
              placeholder={t(`helpCenter.main.placeholder`)}
              value={searchWord}
              onChange={(evt) => {
                setIsSearching(true)
                setSearchWord(evt.target.value)
              }}
            />
          </div>
          <div className={styles.inputSection}>
            {showResults &&
              (filterResult.length > 0 ? (
                <ul className={styles.resultsContainer}>
                  {filterResult.map((ans, index) => {
                    const ansLink =
                      language !== 'en'
                        ? allEnQuestions[allRwQuestions.indexOf(ans)]
                        : ans
                    return (
                      <Link
                        key={`${ans}-${index}`}
                        to={`/help-center/${createURL(ansLink)}`}
                        className={styles.link}
                      >
                        <div className={styles.singleAnswer}>
                          <SearchIcon className={styles.icon} />
                        </div>
                        <span className='text-sm'> {ans}</span>
                      </Link>
                    )
                  })}
                </ul>
              ) : (
                <div className={styles.errorContent}>
                  <p className={styles.errorHeading}>
                    {t('helpCenter.searchPage.noresult.title')}
                  </p>
                  <p className={styles.errorDescription}>
                    {t('helpCenter.searchPage.noresult.description')}
                  </p>
                </div>
              ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Search
