import { Link } from 'react-router-dom'
import styles from './styles'
import createURL from '../../helpers/joinURLString'
import popularQuestions from '../../constants/popularQuestions'
import { allEnQuestions, allRwQuestions } from '../../constants/tableOfContents'
import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'
import { ReactComponent as SearchIcon } from '../../assets/searchIcon.svg'
import { useAppContext } from '../../context'

function HelpCenterHome() {
  const { t } = useTranslation()
  const [searchWord, setSearchWord] = useState('')
  const [isSearching, setIsSearching] = useState(false)
  const [filterResult, setFilterResult] = useState([])
  const { language } = useAppContext()
  const allQuestions = language === 'en' ? allEnQuestions : allRwQuestions

  useEffect(() => {
    const id = setTimeout(() => {
      setIsSearching(!!searchWord.length)
      const questions = allQuestions
        .filter((question) =>
          question.toLowerCase().includes(searchWord.toLowerCase())
        )
        .slice(0, 5)
      if (questions.length) {
        setFilterResult(questions)
      } else {
        SearchUnknown()
      }
    }, 500)

    const SearchUnknown = () => {
      let keywords = searchWord.split(' ')
      let query = []
      keywords.forEach((keyword) => {
        query = query
          .concat(
            allQuestions.filter((q) =>
              q.toLowerCase().includes(keyword.toLowerCase())
            )
          )
          .slice(0, 5)
      })
      setFilterResult(query)
    }

    return () => {
      clearTimeout(id)
    }
  }, [searchWord, allQuestions])

  const showResults = isSearching && searchWord

  return (
    <>
      <h1 className={styles.mainTitle}>{t('helpCenter.main.title')}</h1>
      <div className={styles.mainContent}>
        <p>{t('helpCenter.main.paragraph')}</p>
        <div className={styles.divider}></div>
      </div>
      <div className={styles.inputSection}>
        <h3 className={styles.inputTitle}>{t('helpCenter.main.question')}</h3>
        <div className={`${styles.input}`}>
          <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
            <SearchIcon className='w-5 h-5' />
          </div>
          <input
            type='text'
            className='ml-8 bg-transparent flex-1 p-1 outline-none'
            placeholder={t(`helpCenter.main.placeholder`)}
            value={searchWord}
            onChange={(evt) => {
              setSearchWord(evt.target.value)
            }}
          />
        </div>
        {showResults && (
          <ul
            className={`sidebar
           ${styles.resultContainer}`}
          >
            {filterResult.length > 0 ? (
              filterResult.map((ans) => {
                const ansLink =
                  language !== 'en'
                    ? allEnQuestions[allRwQuestions.indexOf(ans)]
                    : ans

                return (
                  <Link
                    key={ans}
                    to={`${createURL(ansLink)}`}
                    className={styles.link}
                  >
                    <div className='text-black dark:text-white bg-[#EFF2F5] dark:bg-[#5F6770] p-2 rounded-full'>
                      <SearchIcon className='w-4 h-4' />
                    </div>
                    <span className='text-sm'> {ans}</span>
                  </Link>
                )
              })
            ) : (
              <li
                className={`${styles.link} hover:bg-transparent dark:hover:bg-transparent`}
              >
                {t(`helpCenter.main.noresult`)}
              </li>
            )}
          </ul>
        )}
      </div>
      <div className={styles.questionsContainer}>
        <h3 className={styles.questionsTitle}>
          {t('helpCenter.main.popular')}
        </h3>
        <div className={styles.questions}>
          {popularQuestions.map((question) => (
            <Link
              key={question}
              to={`${createURL(question)}`}
              className='hover:underline max-w-fit'
            >
              {t(`helpCenter.sidebar.${question}`)}
            </Link>
          ))}
        </div>
      </div>
    </>
  )
}

export default HelpCenterHome
