import { Outlet } from "react-router-dom";
import styles from "./styles";
import DropDown from "../../components/dropdown";
import items from "../../constants/tableOfContents";
import Footer from "../../pages/footer";

function HelpCenter() {
  return (
    <div className={styles.container}>
        <aside className={styles.sideBar}>
          {items.map((item) => {
            return <DropDown key={item.title} item={item} />;
          })}
        </aside>
        <main className={styles.right}>
          <div className={styles.main}>
            <Outlet />
          </div>
          <Footer />
        </main>
      </div>
  );
}

export default HelpCenter;
