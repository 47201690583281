const feedbackChoices = [
  {
    _id: 1,
    text: "This article didn't answer my questions or solve my problem",
  },
  {
    _id: 2,
    text: 'I found this article confusing or difficult to read',
  },
  {
    _id: 3,
    text: "I don't like how the feature works",
  },
  {
    _id: 4,
    text: 'Other',
  },
]

export default feedbackChoices
