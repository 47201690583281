import Home from "../../pages/home";
import Pricing from "../../pages/pricing";
import Footer from "../../pages/footer";
import Products from "../../pages/products";
import Ussd from "../../pages/ussd";
import styles from "./styles";


function LandingPage() {
  return (
    <div className={styles.container}>
      <div className={styles.containerBody}>
        <Home />
        <Ussd />
        <Products />
        <Pricing />
      </div>
      <Footer />
    </div>
  );
}

export default LandingPage;
