import menuIcon from "../../../assets/menuIcon.png";
import addEmail from "../../../assets/add_email.png";
import profileImage from "../../../assets/profile.png";
import settingIcon from "../../../assets/settings.png";
import { useTranslation } from "react-i18next";
import FeedBack from "../../../components/feedback";

function AccountEmail() {
  const { t } = useTranslation();

  return (
    <article>
      <h1 className="text-xl md:text-2xl font-bold dark:text-secondaryGreen">
        {t("helpCenter.sidebar.How do I add or update my email to my account?")}
      </h1>
      <div className="w-full h-[1px] bg-[#eff2f5] dark:bg-[#303032] mt-4 "></div>
      <section className="mt-8">
        <p className="mb-2">
          <span className="font-semibold">Step 1: </span>
          {t("helpCenter.General.updateEmail.step1.part1")}{" "}
          <img
            src={profileImage}
            alt="profile icon"
            className="inline-flex h-9 w-10 mb-2"
          />{" "}
          {t("helpCenter.General.updateEmail.step1.part2")}{" "}
          <img src={menuIcon} alt="menu icon" className="inline-flex" />{" "}
          {t("helpCenter.General.updateEmail.step1.part3")}{" "}
          <img
            src={settingIcon}
            alt="profile icon"
            className="inline-flex  h-10 w-50"
          />
          
        </p>
        <p className="mb-2">
          <span className="font-semibold">Step 2: </span>
          {t("helpCenter.General.updateEmail.step2.part1")}
          <img
            src={addEmail}
            alt="update email icon"
            className="inline-flex h-10 w-50"
          />
          {t("helpCenter.General.updateEmail.step2.part2")}
        </p>
        <p className="mb-2">
          <span className="font-semibold">Step 3: </span>
          {t("helpCenter.General.updateEmail.step3")}
        </p>
        <p>
          <span className="font-semibold">Step 4: </span>
          {t("helpCenter.General.updateEmail.step4")}
        </p>
        <p>
          <span className="font-semibold">Step 5: </span>
          {t("helpCenter.General.updateEmail.step5")}
        </p>
        <p>
          <span className="font-semibold">Step 6: </span>
          {t("helpCenter.General.updateEmail.step6")}
        </p>
        <p>
          <span className="font-semibold">Step 7: </span>
          {t("helpCenter.General.updateEmail.step7")}
        </p>
      </section>
      <FeedBack />
    </article>
  );
}

export default AccountEmail;
