const styles = {
  container:
    'h-[calc(100vh-110px)] mt-14 flex flex-col items-center bg-white dark:bg-defaultBlack dark:text-white p-4',
  inputContainer: 'w-full md:w-[40em]',
  inputField:
    'relative rounded-2xl bg-[#eff2f5] dark:bg-[#5f6770] p-6 h-10 w-full outline-none flex gap-4 items-center  mt-20',
  input: 'ml-8 bg-transparent p-1 outline-none w-full',
  searchIconContainer:
    'absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none',
  searchIcon: 'w-6 h-6',
  resultsContainer:
    'sidebar absolute flex flex-col bg-white shadow-md dark:bg-[#303030] rounded-xl min-h-content max-h-70 p-3 w-full',
  singleAnswer:
    'text-black dark:text-white bg-[#EFF2F5] dark:bg-[#5F6770] p-2 rounded-full',
  icon: 'w-4 h-4',
  link: 'flex items-center gap-2 p-2 hover:bg-gray-200 dark:hover:bg-gray-500 rounded-xl',
  inputSection: 'mt-8 relative',
  errorContent:
    'flex flex-col items-center pt-20 text-gray-500 dark:text-gray-400',
  errorHeading: 'text-lg font-semibold',
  errorDescription: 'w-full md:w-96 text-center',
}

export default styles
