const osDarkTheme = window.matchMedia('(prefers-color-scheme: dark)').matches
const preferredTheme = localStorage.getItem('uplus.rw-color-theme')

/**
 * Checking if preferred theme is dark
 * @return boolean
*/
export default function checkTheme() {

    if (!preferredTheme) {
        return osDarkTheme === true
    }
    return preferredTheme === 'dark'
}