import styles from "./styles";
import user1 from "../../assets/user1.png";
import user2 from "../../assets/user2.png";
import user3 from "../../assets/user3.png";
import androidDownload from "../../assets/Play_Store.svg";
import iosDownload from "../../assets/Apple_Store.svg";
import { useTranslation } from "react-i18next";

function Home() {
  const { t } = useTranslation();
  return (
    <section className={styles.homeSection}>
      <div className={styles.homeContainer}>
        <div className={styles.homeContent}>
          <div className={styles.homeTextContainer}>
            <div className={styles.homeTextContent}>
              <h2 className={styles.homeTitle}>
                <span className={styles.homeTitleText1}>
                  {t("homePage.title.part1")}
                </span>
                <span className={styles.homeTitleText2}>
                  {t("homePage.title.part2")}
                </span>
                {t("homePage.title.part3")}
              </h2>
              <div className={styles.paragraphsContainer}>
                <p>{t("homePage.paragraph1")}</p>
              </div>
              <p className={styles.specialText}>{t("homePage.paragraph")}</p>
              <div className={styles.homeButtons}>
                <a
                  href="http://bit.ly/uplusRW"
                  className={styles.btnContainer}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={androidDownload}
                    alt="android"
                    className={styles.androidBtn}
                  />
                </a>
                <a
                  href="https://apple.co/2KnmQCE"
                  className={styles.btnContainer}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={iosDownload} alt="ios" className={styles.iosBtn} />
                </a>
              </div>
            </div>
          </div>
          <div className={styles.homeImagesContainer}>
            <div className={styles.homeImagesContent1}>
              <img loading="lazy" decoding="async" src={user1} alt="Customer 1" className={styles.homePhones} />
              <img  loading="lazy" decoding="async" src={user3} alt="Customer 2" className={styles.homePhones} />
            </div>
            <div className={styles.homeImagesContent2}>
              <img  loading="lazy" decoding="async" src={user2} alt="Customer 3" className={styles.homePhones} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Home;
