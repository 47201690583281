import { useState } from 'react'
import i18n from 'i18next'
import styles from './styles'
import { useAppContext } from '../../context'
import { ReactComponent as DownIcon } from '../../assets/down.svg'
import { ReactComponent as RwFlagIcon } from '../../assets/rw.svg'
import { ReactComponent as GbFlagIcon } from '../../assets/gb.svg'

const languages = [
  {
    code: 'en',
    nativeName: 'English',
    icon: <GbFlagIcon className={styles.flagIcon} />,
  },
  {
    code: 'rw',
    nativeName: 'Kinyarwanda',
    icon: <RwFlagIcon className={styles.flagIcon} />,
  },
]

function LanguageSwitcher() {
  const { language, setLanguage } = useAppContext()
  const [isDropDownOpen, setIsDropDownOpen] = useState(false)

  const handleClick = (lngCode) => {
    i18n.changeLanguage(lngCode)
    setLanguage(lngCode)
    setIsDropDownOpen(false)
  }

  const showNativeName = (lng) => {
    for (const language of languages) {
      if (language.code === lng)
        return (
          <span className={styles.btnContent}>
            {language.icon} {language.nativeName}
          </span>
        )
    }
  }

  return (
    <div className={styles.selectContainer}>
      <button
        className={styles.select}
        type='button'
        onClick={() => setIsDropDownOpen((prevState) => !prevState)}
      >
        {showNativeName(language)} <DownIcon className={styles.dropDownIcon} />
      </button>
      {isDropDownOpen && (
        <div className={styles.listContainer}>
          <ul className={styles.languagesList}>
            {languages.map((lng) => {
              return (
                <li
                  key={lng.code}
                  onClick={() => handleClick(lng.code)}
                  className={styles.language}
                >
                  {lng.icon} {lng.nativeName}
                </li>
              )
            })}
          </ul>
        </div>
      )}
    </div>
  )
}

export default LanguageSwitcher
