const styles = {
  pricingSection:
    "sm:text-sm md:text-base lg:text-lg my-8  px-6 md:px-8 xl:px-6  text-black dark:text-white py-12 ",

  descriptionsContainer: "lg:mx-12 flex items-center gap-4 flex-wrap",
  shortLineContainer: "flex items-center",
  shortLine: "block w-10 h-0.5 bg-defaultGreen dark:bg-secondaryGreen",
  lineBullet:
    "block w-3 h-3 rounded-full bg-defaultGreen pt-px dark:bg-secondaryGreen",
  pricingTitle:
    "text-defaultGreen dark:text-secondaryGreen font-semibold text-2xl",
  descriptions: " mt-6",
  pricingContainer:
    "rounded-xl w-full flex  bg-[#f2f6f6] dark:bg-[#242424] flex-col  lg:flex-row gap-4 mt-12 p-6",
  productContent: "flex flex-row gap-4",
  pricingText: "font-semibold text-xl",
  exampleText: "font-semibold",
  productName:
    "text-defaultGreen dark:text-secondaryGreen font-semibold text-2xl mb-3",
  desktopLineContainer: "flex flex-col items-center",
  desktopBullet:
    "block w-3 h-3 rounded-full bg-[#607D7B] dark:bg-defaultGreen pt-px dark:bg-secondaryGreen",
  desktopLine1: "block w-0.5 h-full bg-[#607D7B] dark:bg-defaultGreen",
  desktopLine: "block w-0.5 h-full bg-[#607D7B] dark:bg-defaultGreen",
};
export default styles;
