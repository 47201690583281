import {
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import ContextProvider from "./context";
import LandingPage from "./routes/landingPage";
import TermsAndConditions from "./routes/terms_and_conditions";
import PrivacyPolicy from "./routes/privacy_policy";
import RootLayout from "./routes/RootLayout";
import ErrorPage from "./routes/errorPage";
import HelpCenter from "./routes/help_center";
import HelpCenterHome from "./routes/help_center/home";
import general from "./routes/help_center/general";
import crowdfunding from "./routes/help_center/crowdfunding";
import ikimina from "./routes/help_center/ikimina";
import iSavings from "./routes/help_center/iSavings";
import Search from "./routes/searchPage";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />} errorElement={<ErrorPage />}>
      <Route index element={<LandingPage />} />
      <Route path="/help-center" element={<HelpCenter />}>
        <Route index element={<HelpCenterHome />} />
        {/* GENERAL ROUTES */}
        <Route
          path="how-do-i-create-an-account-on-uplus"
          element={<general.AccountCreation />}
        />
        <Route
          path="how-do-i-login-my-uplus-account"
          element={<general.AccountLogin />}
        />
        <Route
          path="how-do-i-reset-my-password-when-forgotten"
          element={<general.ResetPassword />}
        />
        <Route
          path="how-do-i-add-or-update-my-email-to-my-account"
          element={<general.AccountEmail />}
        />
        <Route
          path="how-do-i-update-my-profile-image"
          element={<general.UpdateProfile />}
        />
        <Route
          path="how-do-i-update-my-username"
          element={<general.EditUser />}
        />
        <Route
          path="how-do-i-add-a-payment-account"
          element={<general.AddPayment />}
        />
        <Route
          path="how-do-i-remove-a-payment-account"
          element={<general.RemovePayment />}
        />
        {/* CROWDFUNDING ROUTES */}

        <Route
          path="how-do-i-create-a-crowdfunding-group"
          element={<crowdfunding.GroupCreation />}
        />
        <Route
          path="how-do-i-join-a-crowdfunding-group"
          element={<crowdfunding.GroupJoining />}
        />
        <Route
          path="how-do-i-edit-a-crowdfunding-group"
          element={<crowdfunding.GroupEdit />}
        />
        <Route
          path="how-do-i-exit-a-crowdfunding-group"
          element={<crowdfunding.GroupExit />}
        />
        <Route
          path="how-do-i-invite-or-add-members-in-an-existing-group"
          element={<crowdfunding.AddMembers />}
        />
        <Route
          path="how-to-contribute-in-a-group"
          element={<crowdfunding.Contribute />}
        />
        <Route
          path="how-to-make-a-withdraw-request-in-crowdfunding-group"
          element={<crowdfunding.Withdraw />}
        />
        <Route
          path="how-to-view-and-approve-a-withdraw-request"
          element={<crowdfunding.ViewWthdraw />}
        />
        <Route
          path="how-do-i-view-all-group-transactions"
          element={<crowdfunding.GroupView />}
        />
        <Route
          path="how-do-i-view-all-transactions-made-by-a-specific-member-in-the-group"
          element={<crowdfunding.TransactionsView />}
        />
        <Route
          path="how-to-share-the-group-ussd-code-to-others"
          element={<crowdfunding.ShareUSSD />}
        />
        <Route
          path="How-Do-I-export-Transactions"
          element={<crowdfunding.ExportTransaction />}
        />
        <Route
          path="How-do-I-view-all-group-pledges"
          element={<crowdfunding.ExportViewPledges />}
        />

        {/* IKIMINA ROUTES */}
        <Route
          path="how-do-i-create-ikimina-group"
          element={<ikimina.CreateGroup />}
        />
        <Route
          path="how-do-i-accept-an-invitation-of-ikimina-group"
          element={<ikimina.AcceptInvite />}
        />
        <Route
          path="how-do-i-invite-or-add-members-in-an-existing-ikimina-group"
          element={<ikimina.AddMembers />}
        />
        <Route
          path="how-do-i-edit-ikimina-group"
          element={<ikimina.EditGroup />}
        />
        <Route
          path="how-do-i-exit-ikimina-group"
          element={<ikimina.ExitGroup />}
        />
        <Route
          path="how-do-i-deposit-my-share-in-a-group"
          element={<ikimina.Contribute />}
        />
        <Route
          path="how-do-i-request-a-loan-in-the-group"
          element={<ikimina.RequestLoan />}
        />
        <Route
          path="how-do-i-payback-the-loan-in-a-group"
          element={<ikimina.Payback />}
        />
        <Route
          path="how-do-i-contribute-penalties-in-ikimina-group"
          element={<ikimina.Penalties />}
        />
        <Route
          path="how-to-make-a-withdraw-request-in-a-group"
          element={<ikimina.Withdraw />}
        />
        <Route
          path="how-to-view-and-approve-any-request-in-the-group"
          element={<ikimina.ViewRequest />}
        />
        <Route
          path="how-do-i-change-group-rules"
          element={<ikimina.ChangeRules />}
        />
        <Route
          path="how-do-i-view-all-ikimina-group-transactions"
          element={<ikimina.ViewGroups />}
        />
        <Route
          path="how-do-i-view-all-ikimina-transactions-made-by-a-specific-member-in-the-group"
          element={<ikimina.ViewTransactions />}
        />
        <Route
          path="How-Do-I-export-Transactions"
          element={<ikimina.ExportTransaction />}
        />

        {/* Individual Savings */}
        <Route path="how-do-i-create-goal" element={<iSavings.CreateGoal />} />
        <Route path="how-do-i-edit-goal" element={<iSavings.EditGoal />} />
        <Route path="how-do-i-Delete-goal" element={<iSavings.DeleteGoal />} />
        <Route
          path="how-do-i-save-for-a-goal"
          element={<iSavings.SaveInGoal />}
        />
        <Route
          path="how-to-make-a-withdraw-of-a-goal"
          element={<iSavings.WithdrawGoal />}
        />
        <Route
          path="how-do-i-view-all-goal-transactions"
          element={<iSavings.ViewGoalTransactions />}
        />
        <Route
          path="how-do-i-export-goal-Transactions"
          element={<iSavings.ExportGoal />}
        />
      </Route>
      <Route path="help-center/search" element={<Search />} />
      <Route path="/terms-conditions" element={<TermsAndConditions />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    </Route>
  )
);

function App() {
  return (
    <ContextProvider>
      <RouterProvider router={router} />
    </ContextProvider>
  );
}

export default App;
