import axios from "axios";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const sendPositiveFeedback = async (articleLink, message) => {
  try {
    return await axios.post(baseUrl, {
      action: "yesThanksApi",
      help_center_article_link: articleLink,
      message: message,
    });
  } catch (error) {
    console.log({ error });
  }
};

export const sendNegativeFeedback = async (articleLink, reason, reasonInfo) => {
  try {
    return await axios.post(baseUrl, {
      action: "notReallyApi",
      help_center_article_link: articleLink,
      reason,
      more_info_about_the_reason: reasonInfo,
    });
  } catch (error) {
    console.log(error);
  }
};
