import { useLocation } from "react-router-dom";

const useLinks = () => {
  const location = useLocation();
  let links = [
    { name: "Home", href: "/#home" },
    { name: "Products", href: "/#products" },
    { name: "Pricing", href: "/#pricing" },
    { name: "Help Center", href: "/help-center" },
  ];

  if (
    location.pathname === "/privacy-policy" ||
    location.pathname === "/terms-conditions" ||
    location.pathname.includes("help-center")
  ) {
    links = links.filter((item) => item.name === "Home");
    links.at(0).href = "/";
    if (location.pathname.includes("help-center")) {
      links.push({ name: "search", href: "/help-center/search" });
    }
  }

  return links;
};

export default useLinks;
