import CreateGoal from "./createGoal";
import EditGoal from "./editGoal";
import DeleteGoal from "./deleteGoal";
import ExportGoal from "./exportGoal";
import ViewGoalTransactions from "./goalTransactions";
import SaveInGoal from "./saveInGoal";
import WithdrawGoal from "./withdrawGoal";

const iSavings = {
  CreateGoal,
  EditGoal,
  DeleteGoal,
  ExportGoal,
  ViewGoalTransactions,
  SaveInGoal,
  WithdrawGoal,
};
export default iSavings;
