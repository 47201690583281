import styles from "./styles";
function GetStarted() {
  return (
    <a
      href="https://portal.uplus.rw/create-an-account"
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className={styles.container}>
        <span className="font-bold text-base font-inter"> Get Started </span>
      </div>
    </a>
  );
}

export default GetStarted;
