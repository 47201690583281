import styles from "./styles";
import { useTranslation } from "react-i18next";
import crowdFundingArtWork from "../../assets/pricing.png";

function Pricing() {
  const { t } = useTranslation();

  return (
    <section id="pricing" className={styles.pricingSection}>
      <div>
        <div className={styles.descriptionsContainer}>
          <div className={styles.shortLineContainer}>
            <span className={styles.shortLine}></span>
            <span className={styles.lineBullet}></span>
          </div>
          <h2 className={styles.pricingTitle}>{t("pricingPage.title")}</h2>
        </div>
      </div>
      <div className={styles.pricingContainer}>
        <div className="flex flex-col items-center gap-4 w-full lg:w-[60%]">
          <div className={styles.productContent}>
            <div className={styles.desktopLineContainer}>
              <span className={styles.desktopBullet}></span>
              <span className={styles.desktopLine1}></span>
            </div>
            <div>
              <h4 className={styles.productName}>
                {t("pricingPage.contribute")}
              </h4>
              <p>
                {t("pricingPage.contribute_description.part1")}
                <span className={styles.pricingText}> 2% </span>
                {t("pricingPage.contribute_description.part2")}
              </p>
            </div>
          </div>
          <div className={styles.productContent}>
            <div className={styles.desktopLineContainer}>
              <span className={styles.desktopBullet}></span>
              <span className={styles.desktopLine}></span>
            </div>
            <div>
              <h4 className={styles.productName}>
                {t("pricingPage.withdrawing")}
              </h4>
              <p>{t("pricingPage.withdrawing_description.part1")}</p>
            </div>
          </div>
        </div>
        <div className="flex items-center lg:h-[300px] justify-center mx-auto w-1/2 lg:w-[40%]">
          <span className=" ">
            <img src={crowdFundingArtWork} alt="pricing1" className="" />
          </span>
        </div>
      </div>
    </section>
  );
}

export default Pricing;
