const styles = {
  container: 'py-4',
  lineDrawer: 'w-full h-[1px] bg-[#eff2f5] dark:bg-[#303032] mt-4',
  feedbackContent: 'flex flex-col justify-center items-center py-4 my-4',
  feedbackHeading: 'font-bold',
  feedbackIcon: 'inline',
  btnContainer: 'flex gap-12 mt-6',
  btn: 'border border-defaultGreen rounded-md hover:bg-defaultGreen hover:text-white px-2 py-1 duration-75 w-32',
}
export default styles
