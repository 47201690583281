import { useId, useState } from 'react'
import styles from './styles'
import { useTranslation } from 'react-i18next'
import editIcon from '../../../assets/edit.svg'
import feedbackChoices from '../../../constants/feedbackChoises'
import { sendPositiveFeedback, sendNegativeFeedback } from '../../../api'

const TextField = ({
  setUserAnswer,
  userAnswer,
  placeholder,
  setIsToastShown,
  setFeedBackRequest,
}) => {
  const { t } = useTranslation()
  const defaultReason = userAnswer === 'no' ? 'Other' : ''
  const articleLink = window.location.href
  const [wordCount, setWordCount] = useState(0)
  const [userFeedbackReason, setUserFeedbackReason] = useState(defaultReason)
  const [userFeedback, setUserFeedback] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const id = useId()
  const maxWords = 600

  const handleChange = (evt) => {
    const feedback = evt.target.value
    setUserFeedback(feedback)
    setWordCount(feedback.length)
  }

  const handleSubmit = async (evt) => {
    evt.preventDefault()
    try {
      setIsLoading(true)
      if (!userFeedbackReason) {
        await sendPositiveFeedback(articleLink, userFeedback)
      } else {
        await sendNegativeFeedback(
          articleLink,
          userFeedbackReason,
          userFeedback
        )
      }
      setUserFeedbackReason('')
      setUserFeedback('')
      setUserAnswer('')
      setWordCount(0)
      setIsLoading(false)
      setFeedBackRequest(false)
      setIsToastShown(true)
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div>
      {userAnswer === 'no' ? (
        <div>
          <p className={styles.textFieldHeading}>
            <img
              src={editIcon}
              alt='edit-icon'
              className={styles.headingIcon}
            />{' '}
            {t('helpCenter.feedbackPortion.reason.title')}
          </p>
          <div className={styles.feedbaCkChoices}>
            {feedbackChoices.map((choice) => (
              <div key={choice._id}>
                <input
                  type='radio'
                  name='feedback-reason'
                  id={`${id}-${choice._id}`}
                  value={choice.text}
                  checked={userFeedbackReason === choice.text}
                  onChange={(evt) => setUserFeedbackReason(evt.target.value)}
                />
                <label
                  htmlFor={`${id}-${choice._id}`}
                  className={styles.inputLabel}
                >
                  {t(`helpCenter.feedbackPortion.reason.${choice.text}`)}
                </label>
              </div>
            ))}
          </div>
        </div>
      ) : null}
      <form>
        <div className={styles.formContainer}>
          <div className={styles.formContent}>
            <label htmlFor='comment' className={styles.readerOnly}>
              Your comment
            </label>
            <textarea
              id='comment'
              rows='4'
              maxLength={maxWords}
              className={styles.textFieldArea}
              placeholder={placeholder}
              onChange={handleChange}
              value={userFeedback}
            ></textarea>
          </div>
          <div className={styles.formFooter}>
            <div className={styles.wordCount}>
              {wordCount}/{maxWords}
            </div>

            <button
              type='submit'
              className={
                isLoading || !userFeedback ? styles.disabled : styles.submitBtn
              }
              onClick={handleSubmit}
              disabled={isLoading || !userFeedback}
            >
              {isLoading
                ? t(`helpCenter.feedbackPortion.feedbackBtn.sending`)
                : t(`helpCenter.feedbackPortion.feedbackBtn.submit`)}
            </button>
          </div>
        </div>
      </form>
      <p className={styles.textFieldFooter}>
        {t(`helpCenter.feedbackPortion.footer.part1`)}
        <a href='tel:+250787496316' className={styles.footerLink}>
          {t(`helpCenter.feedbackPortion.footer.part2`)}
        </a>{' '}
        {t(`helpCenter.feedbackPortion.footer.part3`)}
        <a href='mailto: info@uplus.rw' className={styles.footerLink}>
          {t(`helpCenter.feedbackPortion.footer.part4`)}
        </a>
      </p>
    </div>
  )
}

export default TextField
