import styles from "./styles";
import SingleNavItem from "../singleNavItem";
import { ReactComponent as MenuICon } from "../../assets/menu.svg";
import { ReactComponent as CloseIcon } from "../../assets/close.svg";
import { useAppContext } from "../../context";
import ThemeToggle from "../themeToggle";
import LanguageSwitcher from "../languageSwitcher";
import useLinks from "../../helpers/useLinks";
import { useLocation } from "react-router-dom";
import DropDown from "../dropdown";
import items from "../../constants/tableOfContents";
import { ReactComponent as SearchIcon } from "../../assets/searchIcon.svg";
import { Link } from "react-router-dom";
import GetStarted from "../getStarted";

function NavItems() {
  const { isMenuOpen, setIsMenuOpen, dropDownLinks, setDropDownLinks } =
    useAppContext();

  const links = useLinks();
  const location = useLocation();
  return (
    <div>
      {/* mobile view */}
      <div className={styles.menuBtnContainer}>
        {location.pathname.includes("help-center") && (
          <Link to={"/help-center/search"} className={styles.link}>
            <SearchIcon className={styles.search} />
          </Link>
        )}
        <button
          type={styles.btn}
          className={styles.menuBtn}
          onClick={() => {
            setIsMenuOpen(true);
          }}
        >
          <span className={styles.scrReaderOnly}>Open main menu</span>
          <MenuICon className={styles.menuIcon} />
        </button>
      </div>
      <div className={styles.mobileView}>
        <div
          className={`${styles.linksContainer} ${isMenuOpen ? styles.onMenuOpen : styles.onMenuClose
            } ${location.pathname.includes("help-center")
              ? styles.helpCenterTheme
              : styles.landigPageTheme
            }`}
        >
          <div className={styles.closeBtnContainer}>
            <button
              type={styles.btn}
              className={`${styles.closeBtn} ${location.pathname.includes("help-center")
                  ? styles.helpCenterText
                  : styles.landingPageText
                }`}
              onClick={() => {
                setIsMenuOpen(false);
              }}
            >
              <CloseIcon className={styles.closeIcon} />
              <span className={styles.scrReaderOnly}>Close main menu</span>
            </button>
          </div>
          <div className="mb-3 ml-4">
            {location.pathname.includes("help-center") ? (
              <div className="mb-3">
                {items.map((item) => {
                  return (
                    <DropDown
                      key={item.title}
                      item={item}
                      dropDownLinks={dropDownLinks}
                      setDropDownLinks={setDropDownLinks}
                    />
                  );
                })}
              </div>
            ) : (
              <ul className={styles.mobileLinks}>
                {links.map((item) => (
                  <SingleNavItem key={item.name} item={item} />
                ))}
              </ul>
            )}
            <div className="flex flex-col gap-4">
              <ThemeToggle />
              <LanguageSwitcher />
              <div
                className={
                  location.pathname.includes("/help-center") ||
                    location.pathname.includes("/privacy-policy") ||
                    location.pathname.includes("/terms-conditions")
                    ? "hidden"
                    : "block lg:hidden mb-4"
                }
              >
                <GetStarted />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* desktop view */}
      <div className={styles.desktopView}>
        <ul className={styles.desktopLinks}>
          {links.map((item) => (
            <SingleNavItem key={item.name} item={item} />
          ))}
        </ul>
        <div className="flex flex-row gap-4">
          <LanguageSwitcher />
          <ThemeToggle />
        </div>
      </div>
    </div>
  );
}

export default NavItems;
