import { useEffect, useState } from 'react';
import styles from './styles';
import { ReactComponent as ArrowIcon } from '../../assets/topArrow.svg';
import { useAppContext } from '../../context';

function FloatingBtn() {
  const [showBtn, setShowBtn] = useState(false);
  const { setActiveLink } = useAppContext();
  const checkTopHeight = () => {
    const scrollContainer = document.documentElement || document.body;
    const navHeight = 55;
    if (scrollContainer.scrollTop > navHeight) {
      setShowBtn(true);
    } else {
      setShowBtn(false);
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', checkTopHeight);
    return () => document.removeEventListener('scroll', checkTopHeight);
  });

  const goToTop = () => {
    document.body.scrollIntoView();
  };

  return (
    <button
      onClick={() => {
        goToTop();
        setActiveLink('Home');
      }}
      className={`${showBtn ? styles.onShowBtn : styles.onHideBtn} ${
        styles.btn
      }`}
    >
      <ArrowIcon className={styles.arrowIcon} />
    </button>
  );
}

export default FloatingBtn;
