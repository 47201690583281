import AccountCreation from './accountCreation'
import AccountEmail from './accountEmail'
import AccountLogin from './accountLogin'
import ResetPassword from './resetPassword'
import UpdateProfile from './updateProfile'
import EditUser from './updateUser'
import AddPayment from './addPayment'
import RemovePayment from './removePayment'

const general = {
  AccountCreation,
  AccountEmail,
  AccountLogin,
  ResetPassword,
  UpdateProfile,
  EditUser,
  AddPayment,
  RemovePayment,
}

export default general
