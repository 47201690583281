import { useTranslation } from "react-i18next";
import FeedBack from "../../../components/feedback";
import filterdate from "../../../assets/selectDate.png";
import filterall from "../../../assets/filterAll.png";
function GroupView() {
  const { t } = useTranslation();

  return (
    <article>
      <h1 className="text-xl md:text-2xl font-bold dark:text-secondaryGreen">
        {t("helpCenter.sidebar.How do I view all group transactions?")}
      </h1>
      <div className="w-full h-[1px] bg-[#eff2f5] dark:bg-[#303032] mt-4 "></div>
      <section className="mt-8">
        <p className="mb-2">
          <span className="font-semibold">Step 1: </span>
          {t("helpCenter.Crowdfunding.viewGroups.step1")}
        </p>
        <p>
          <span className="font-semibold">Step 2: </span>
          {t("helpCenter.Crowdfunding.viewGroups.step2.part1")}
        </p>
        <br />
        <span className="font-semibold">Note: </span>
        <ul className="list-disc pl-8">
          {" "}
          <li>
            {t("helpCenter.Crowdfunding.viewGroups.step2.note1.part1")}
            <img
              src={filterdate}
              alt="icon"
              className="inline-flex w-32"
            />{" "}
            {t("helpCenter.Crowdfunding.viewGroups.step2.note1.part2")}
          </li>
          <li>
            {t("helpCenter.Crowdfunding.viewGroups.step2.note2.part1")}
            <img src={filterall} alt="icon" className="inline-flex w-20" />
            {t("helpCenter.Crowdfunding.viewGroups.step2.note2.part2")}
          </li>
        </ul>
      </section>
      <FeedBack />
    </article>
  );
}

export default GroupView;
