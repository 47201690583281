import { useTranslation } from "react-i18next";
import FeedBack from "../../../components/feedback";
import creatGoal from "../../../assets/createGoal.png";
function CreateGoal() {
  const { t } = useTranslation();
  return (
    <article>
      <h1 className="text-xl md:text-2xl font-bold dark:text-secondaryGreen">
        {t("helpCenter.sidebar.How do I create goal?")}
      </h1>
      <div className="w-full h-[1px] bg-[#eff2f5] dark:bg-[#303032] mt-4 "></div>
      <section className="mt-8">
        <p className="mb-2">
          <span className="font-semibold">Step 1: </span>
          {t("helpCenter.ISavings.createGoal.step1")}
        </p>
        <p className="mb-2">
          <span className="font-semibold">Step 2: </span>
          {t("helpCenter.ISavings.createGoal.step2.part1")}
          <img src={creatGoal} alt="icon" className="inline-flex w-12" />
          {t("helpCenter.ISavings.createGoal.step2.part2")}
        </p>
        <p className="mb-2">
          <span className="font-semibold">Step 3: </span>
          {t("helpCenter.ISavings.createGoal.step3")}
        </p>
        <p className="mb-2">
          <span className="font-semibold">Step 4: </span>
          {t("helpCenter.ISavings.createGoal.step4")}
        </p>
        <p className="mb-2">
          <span className="font-semibold">Step 5: </span>
          {t("helpCenter.ISavings.createGoal.step5.part1")}
          <ul className=" list-disc pl-8">
            <li> {t("helpCenter.ISavings.createGoal.step5.part2")}</li>
            <li> {t("helpCenter.ISavings.createGoal.step5.part3")}</li>
            <li> {t("helpCenter.ISavings.createGoal.step5.part4")}</li>
          </ul>
        </p>
        <p className="mb-2">
          <span className="font-semibold">Step 6: </span>
          {t("helpCenter.ISavings.createGoal.step6.part1")}
          <ul className=" list-disc pl-8">
            <li> {t("helpCenter.ISavings.createGoal.step6.part2")}</li>
            <li> {t("helpCenter.ISavings.createGoal.step6.part3")}</li>
            <li> {t("helpCenter.ISavings.createGoal.step6.part4")}</li>
            <li> {t("helpCenter.ISavings.createGoal.step6.part5")}</li>
          </ul>
        </p>
      </section>
      <FeedBack />
    </article>
  );
}

export default CreateGoal;
