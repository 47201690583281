import { useTranslation } from 'react-i18next'
import FeedBack from '../../../components/feedback'

function ViewWthdraw() {
  const { t } = useTranslation()

  return (
    <article>
      <h1 className='text-xl md:text-2xl font-bold dark:text-secondaryGreen'>
        {t('helpCenter.sidebar.How to view and approve a withdraw request?')}
      </h1>
      <div className='w-full h-[1px] bg-[#eff2f5] dark:bg-[#303032] mt-4 '></div>
      <section className='mt-8'>
        <p className='mb-2'>
          <span className='font-semibold'>Step 1: </span>
          {t('helpCenter.Crowdfunding.viewWithdraw.step1')}
        </p>
        <p className='mb-2'>
          <span className='font-semibold'>Step 2: </span>
          {t('helpCenter.Crowdfunding.viewWithdraw.step2')}
        </p>
        <p className='mb-2'>
          <span className='font-semibold'>Step 3: </span>
          {t('helpCenter.Crowdfunding.viewWithdraw.step3')}
        </p>
        <p>
          <span className='font-semibold'>Step 4: </span>
          {t('helpCenter.Crowdfunding.viewWithdraw.step4')}
          <br /> <span className='font-semibold'>Note:</span>{' '}
          {t('helpCenter.Crowdfunding.viewWithdraw.note')}
        </p>
      </section>
      <FeedBack />
    </article>
  )
}

export default ViewWthdraw
