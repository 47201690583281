import styles from "./styles";
import Logo from "../../components/logo";
import { ReactComponent as TwitterIcon } from "../../assets/twitter.svg";
import { ReactComponent as InstagramIcon } from "../../assets/instagram.svg";
import { ReactComponent as FacebookIcon } from "../../assets/facebook.svg";
import { ReactComponent as LinkedinIcon } from "../../assets/linkedin.svg";
import androidDownload from "../../assets/Play_Store.svg";
import iosDownload from "../../assets/Apple_Store.svg";
import telephone from "../../assets/Telephone.png";
import email from "../../assets/Secured Letter.svg";
import LanguageSwitcher from "../../components/languageSwitcher";
import { useAppContext } from "../../context";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import useLinks from "../../helpers/useLinks";

function Footer() {
  const { t } = useTranslation();
  const { setActiveLink } = useAppContext();
  const currentYear = new Date().getFullYear();
  let landingPageLinks = useLinks();
  // removing  search link in the footer
  landingPageLinks = landingPageLinks.filter((link) => link.name !== "search");
  const location = useLocation();

  const goToTop = () => {
    document.body.scrollIntoView();
  };

  return (
    <footer className={styles.footerSection}>
      <div className={styles.container}>
        <div className={styles.sectionContainer}>
          <Logo />
          <p className={styles.overviewText}>{t("footerPage.overview")}</p>
          <div className={styles.socialLinksContainer}>
            <a
              href="https://facebook.com/UplusRw"
              target="_blank"
              rel="noopener noreferrer"
              className=" hover:text-defaultYellow duration-150 "
            >
              <FacebookIcon className={styles.socialIcon} />
              <span className={styles.screeenOnly}>Facebook page</span>
            </a>
            <a
              href="https://linkedin.com/company/uplus-mutual-partners/"
              target="_blank"
              rel="noopener noreferrer"
              className=" hover:text-defaultYellow duration-150"
            >
              <LinkedinIcon className={styles.socialIcon} />
              <span className={styles.screeenOnly}>LinkedIn account</span>
            </a>
            <a
              href="https://twitter.com/Uplusrw"
              target="_blank"
              rel="noopener noreferrer"
              className=" hover:text-defaultYellow duration-150 "
            >
              <TwitterIcon className={styles.socialIcon} />
              <span className={styles.screeenOnly}>Twitter page</span>
            </a>
            <a
              href="https://instagram.com/Uplusrw/"
              target="_blank"
              rel="noopener noreferrer"
              className=" hover:text-defaultYellow duration-150 "
            >
              <InstagramIcon className={styles.socialIcon} />
              <span className={styles.screeenOnly}>Instagram page</span>
            </a>
          </div>
        </div>
        <div className={styles.sectionContainer}>
          <h2 className={styles.sectionTitle}>
            {t("footerPage.supportSection.title")}
          </h2>
          <ul>
            {landingPageLinks.map((item) => (
              <li
                className={styles.item}
                onClick={() => setActiveLink(item.name)}
                key={item.name}
              >
                {location.pathname === "/" ? (
                  <a href={item.href} className={styles.link}>
                    {t(
                      `footerPage.supportSection.links.${item.name.toLowerCase()}`
                    )}
                  </a>
                ) : (
                  <Link
                    to={item.href}
                    className={styles.link}
                    onClick={() => {
                      goToTop();
                      setActiveLink(item.name);
                    }}
                  >
                    {t(
                      `footerPage.supportSection.links.${item.name.toLowerCase()}`
                    )}
                  </Link>
                )}
              </li>
            ))}
            <li
              className={styles.item}
              onClick={() => {
                goToTop();
                setActiveLink("privacy");
              }}
            >
              <Link to={`/privacy-policy`} className={styles.link}>
                {t("footerPage.supportSection.links.privacy_policy")}
              </Link>
            </li>
            <li
              className={styles.item}
              onClick={() => {
                goToTop();
                setActiveLink("privacy");
              }}
            >
              <Link to={`/terms-conditions`} className={styles.link}>
                {t("footerPage.supportSection.links.terms_conditions")}
              </Link>
            </li>
            <li className="mb-4 hover:text-defaultYellow no-underline duration-150">
              <a
                href="https://portal.uplus.rw"
                target="_blank"
                rel="noopener noreferrer"
              >
                Client Portal
              </a>
            </li>
          </ul>
        </div>
        <div>
          <h2 className={styles.sectionTitle}>
            {t("footerPage.contactSection.title")}
          </h2>
          <ul>
            <li className={styles.item + " flex flex-row items-center  gap-1"}>
              <span>
                <img src={email} alt="email" />
              </span>
              <span>
                <a href="mailto: info@uplus.rw" className={styles.link}>
                  {t("footerPage.contactSection.email")}
                </a>
              </span>
            </li>
            <li className={styles.item + " flex flex-row items-center  gap-1"}>
              <span>
                <img src={telephone} alt="telephone" />
              </span>
              <span>
                <a href="tel:+250787496316" className={styles.link}>
                  {t("footerPage.contactSection.telephone")}
                </a>
              </span>
            </li>
          </ul>
        </div>
        <div>
          <div className={styles.lastSectionContainer}>
            <a
              href="http://bit.ly/uplusRW"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.downloadBtnContainer}
            >
              <img
                src={androidDownload}
                alt="android download link"
                className={styles.androidBtn}
              />
            </a>
            <a
              href="https://apple.co/2KnmQCE"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.downloadBtnContainer}
            >
              <img
                src={iosDownload}
                alt="ios download link"
                className={styles.iosBtn}
              />
            </a>
            {!location.pathname.includes("/help-center") && (
              <div className={styles.languageContainer}>
                <LanguageSwitcher />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.rightsContainer}>
        <p>Uplus Mutual Partners Ltd. &copy; {currentYear}</p>
      </div>
    </footer>
  );
}

export default Footer;
