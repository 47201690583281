const styles = {
  selectContainer:
    "text-sm md:text-base relative  md:my-0 text-black font-medium",
  select:
    "bg-white w-44 font-semibold rounded-md text-sm p-2 justify-between inline-flex items-center  border border-blue-400",
  languagesList: "py-1 font-semibold text-sm",
  language:
    "cursor-pointer p-2 inline-flex items-center w-full gap-2 hover:bg-gray-200",
  dropDownIcon: "w-5 h-5 text-defaultBlack",
  flagIcon: "h-6 w-6",
  listContainer:
    "w-44 bg-white absolute rounded-md top-auto right-auto bottom-auto left-0 m-0 border border-blue-400",
  btnContent: "inline-flex items-center gap-2",
};
export default styles;
