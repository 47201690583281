import { useTranslation } from 'react-i18next'
import FeedBack from '../../../components/feedback'

function PersonalAccount() {
  const { t } = useTranslation()

  return (
    <article>
      <h1 className='text-xl md:text-2xl font-bold dark:text-secondaryGreen'>
        {t('helpCenter.sidebar.How do I create a personal account?')}
      </h1>
      <div className='w-full h-[1px] bg-[#eff2f5] dark:bg-[#303032] mt-4 '></div>
      <section className='mt-8'>
        <p className='mb-2'>
          <span className='font-semibold'>Step1: </span>
          {t('helpCenter.Crowdfunding.personalAccount.step1')}
        </p>
        <p className='mb-2'>
          <span className='font-semibold'>Step2: </span>
          {t('helpCenter.Crowdfunding.personalAccount.step2')}
        </p>
        <p className='mb-2'>
          <span className='font-semibold'>Step3: </span>
          {t('helpCenter.Crowdfunding.personalAccount.step3')}
        </p>
        <p className='mb-2'>
          <span className='font-semibold'>Step4: </span>
          {t('helpCenter.Crowdfunding.personalAccount.step4')}
        </p>
        <p className='mb-2'>
          <span className='font-semibold'>Step5: </span>
          {t('helpCenter.Crowdfunding.personalAccount.step5')}
        </p>
        <p>
          <span className='font-semibold'>Step6: </span>
          {t('helpCenter.Crowdfunding.personalAccount.step6')}
        </p>
      </section>
      <FeedBack />
    </article>
  )
}

export default PersonalAccount
