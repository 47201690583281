import { NavLink } from "react-router-dom";
import styles from "./styles";
import { ReactComponent as ArrowDown } from "../../assets/down.svg";
import { ReactComponent as ArrowUp } from "../../assets/up.svg";
import formURL from "../../helpers/joinURLString";
import logo from "../../assets/dropdownLogo.svg";
import crowdfund from "../../assets/Crowdfunding.svg"
import ikimina from "../../assets/ikimina.svg"
import iSavings from "../../assets/iSavings.svg"
import crowdfundLight from "../../assets/CrowdfundingLight.png"
import ikiminaLight from "../../assets/ikiminaLight.png"
import iSavingsLight from "../../assets/iSavingsLight.png"
import { useAppContext } from "../../context";
import { useTranslation } from "react-i18next";

function DropDown({ item }) {
  const { t } = useTranslation();
  const { setIsMenuOpen, dropDownLinks, setDropDownLinks, isDarkMode } = useAppContext();

  return (
    <div className="border-b border-opacity-10 border-gray-500 dark:border-white dark:border-opacity-20">
      <button
        className={styles.btn}
        onClick={() =>
          setDropDownLinks((prev) => (prev === item.title ? "" : item.title))
        }
      >
        {item.title === "Crowdfunding" ?
          isDarkMode ?
            < img src={crowdfundLight} alt="logo" className={styles.icon} /> :
            < img src={crowdfund} alt="logo" className={styles.icon} /> :
          item.title === "Ikimina" ?
            isDarkMode ?
              < img src={ikiminaLight} alt="logo" className={styles.icon} /> :
              <img src={ikimina} alt="logo" className={styles.icon} /> :
            item.title === "Individual Savings" ?
              isDarkMode ?
                < img src={iSavingsLight} alt="logo" className={styles.icon} /> :
                <img src={iSavings} alt="logo" className={styles.icon} /> :
              <img src={logo} alt="logo" className={styles.image} />
        }
        <div className={styles.currentLanguage}>
          {t(`helpCenter.sidebar.${item.title}`)}
        </div>
        {dropDownLinks === item.title ? <ArrowUp /> : <ArrowDown />}
      </button>
      <ul
        className={`${styles.listContainer} ${dropDownLinks === item.title ? styles.showList : styles.hideList
          }`}
      >
        {item.subtitles.map((subtitle) => {
          return (
            <li
              key={subtitle}
              className={styles.listItem}
              onClick={() => setIsMenuOpen(false)}
            >
              <NavLink
                to={`/help-center/${formURL(subtitle)}`}
                className={({ isActive }) =>
                  isActive ? styles.active : styles.link
                }
              >
                {t(`helpCenter.sidebar.${subtitle}`)}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default DropDown;
