const styles = {
  container:
    "mt-14 relative pt-12 bg-white dark:bg-defaultBlack dark:text-white md:flex lg:gap-16",
  sideBar:
    "hidden md:fixed md:top-14 md:bottom-0 md:left-0 lg:block md:w-60 lg:w-80  md:overflow-y-hidden md:hover:overflow-y-scroll sidebar shadow-md dark:bg-[#000] pt-3",
  right: "lg:ml-80 w-full",
  main: "px-4 md:w-5/6  lg:mx-10 mt-3 mb-8 min-h-[calc(100vh-27.5em)]",
  mainTitle: "text-xl md:text-2xl font-black dark:text-secondaryGreen",
  mainContent: "flex flex-col items-center md:items-start my-4",
  divider: "w-full h-[1px] bg-[#eff2f5] dark:bg-[#303032] mt-4 ",
  inputSection: "mt-8 relative",
  inputTitle: "font-bold text-lg my-4",
  input:
    "relative rounded-xl bg-[#eff2f5] dark:bg-[#5f6770] p-2 h-10 w-full lg:w-2/3 outline-none flex gap-2 items-center",
  questionsContainer: "my-8",
  questionsTitle: "font-bold text-lg my-4",
  questions: "text-defaultGreen dark:text-secondaryGreen flex flex-col gap-2",
  resultContainer:
    "absolute mt-4 flex flex-col bg-white shadow-md dark:bg-[#303030] rounded-xl min-h-content max-h-70 p-2 w-full lg:w-2/3",
  link: "flex items-center gap-2 p-2 hover:bg-gray-200 dark:hover:bg-gray-500 rounded-xl",
};

export default styles;
