const styles = {
  textFieldHeading: 'text-center text-sm font-semibold mb-4',
  headingIcon: 'inline',
  feedbaCkChoices: 'flex flex-col my-4 text-sm',
  inputLabel: 'ml-2',
  formContainer: 'w-full mb-4',
  formContent:
    'px-4 py-2 border border-gray-300 rounded-xl dark:border-gray-400',
  readerOnly: 'sr-only',
  textFieldArea:
    'w-full px-0 text-sm text-gray-900 bg-white border-0 dark:bg-defaultBlack focus:ring-0 dark:text-white dark:placeholder-gray-400 outline-none',
  formFooter: 'flex justify-between py-2',
  wordCount: 'text-xs text-gray-400 dark:text-gray-400',
  submitBtn:
    'block mt-4 py-2.5 px-4 text-xs font-medium text-center  border border-defaultGreen rounded-md hover:bg-defaultGreen hover:text-white  duration-75',
  disabled:
    'bg-defaultGray block mt-4 py-2.5 px-4 text-xs font-medium text-center  border border-defaultGreen rounded-md text-white duration-75 cursor-not-allowed',
  textFieldFooter: 'ml-auto text-sm font-medium ',
  footerLink: 'text-blue-600 dark:text-blue-500 hover:underline',
}
export default styles
