import check from "../../../assets/selectAccount.png";
import { useTranslation } from "react-i18next";
import FeedBack from "../../../components/feedback";

function Contribute() {
  const { t } = useTranslation();

  return (
    <article>
      <h1 className="text-xl md:text-2xl font-bold dark:text-secondaryGreen">
        {t("helpCenter.sidebar.How to contribute in a group?")}
      </h1>
      <div className="w-full h-[1px] bg-[#eff2f5] dark:bg-[#303032] mt-4 "></div>
      <section className="mt-8">
        <p className="mb-2">
          <span className="font-semibold">Step 1: </span>
          {t("helpCenter.Crowdfunding.contribute.step1")}
        </p>
        <p className="mb-2">
          <span className="font-semibold">Step 2: </span>
          {t("helpCenter.Crowdfunding.contribute.step2")}
        </p>
        <p className="mb-2">
          <span className="font-semibold">Step 3: </span>{" "}
          {t("helpCenter.Crowdfunding.contribute.step3")}
        </p>
        <p className="mb-2">
          <span className="font-semibold">Step 4: </span>
          {t("helpCenter.Crowdfunding.contribute.step4")}
          <br /> <span className="font-semibold">Note: </span>
          {t("helpCenter.Crowdfunding.contribute.note.part1")}
          <img src={check} alt="icon" className="inline-flex w-[160px]" />
          {t("helpCenter.Crowdfunding.contribute.note.part2")}
        </p>
        <p>
          <span className="font-semibold">Step 5: </span>
          {t("helpCenter.Crowdfunding.contribute.step5")}
          <br /> <span className="font-semibold">Note: </span>
          {t("helpCenter.Crowdfunding.contribute.note1")}
        </p>
      </section>
      <FeedBack />
    </article>
  );
}

export default Contribute;
