const styles = {
  currentLanguage: 'flex-1 md:text-left',
  btn: 'flex font-semibold text-md p-3 gap-3 justify-between items-center w-full',
  image: 'bg-defaultGreen  rounded-full w-9 h-9',
  icon: ' w-8 h-8',
  showList: 'block',
  hideList: 'hidden',
  listContainer: 'my-2 mx-3',
  listItem: 'md:my-2 rounded-md hover:bg-gray-200 dark:hover:bg-gray-500',
  link: 'block p-3 md:p-2 font-semibold text-sm',
  active:
    'block p-3 md:p-2 font-semibold text-sm rounded-md bg-gray-200 dark:bg-defaultGreen ',
}

export default styles
