const styles = {
  homeIcon: 'h-8 w-8',
  container: 'h-screen flex flex-col sm:items-center justify-center px-8',
  darkContainer: 'bg-defaultBlack text-white',
  whiteContainer: 'bg-white text-black',
  content: 'text-center',
  heading: 'text-xl sm:text-2xl font-bold my-6',
  text: 'text-lg sm:text-xl',
  contentBackground:
    'bg-homeBackImage bg-contain bg-center bg-no-repeat md:errorPage',
  linkContainer: 'mt-12 text-center ',
  link: 'text-white bg-defaultGreen w-full px-8 py-2 rounded-3xl inline-flex justify-center gap-3 items-center text-lg',
};
export default styles;
