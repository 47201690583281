import styles from "./styles";
import { useState, useRef } from "react";
import { useEffect } from "react";
import crowdFundImage from "../../assets/CrowdFundApp.png";
import ikiminaImage from "../../assets/ikiminaApp.png";
import webPortalImage from "../../assets/ClientPortalAPP.png";
import iSavings from "../../assets/IndividualSavingsApp.png";
import { useTranslation } from "react-i18next";

function Products() { 
  const { t } = useTranslation();
  const [showBtn, setShowBtn] = useState(false);
  const [showCrowdfund, setshowCrowdfund] = useState(false);
  const [showIkimina, setshowIkimina] = useState(false);
  const [showISavings, setShowISavings] = useState(false);
  const [showPortal, setShowPortal] = useState(false);

  const crowdfundRef = useRef(null);
  const ikiminaRef = useRef(null);
  const iSavingsRef = useRef(null);
  const clientPortalRef = useRef(null);
 
  const checkTopHeight = () => {
    const scrollContainer = document.documentElement || document.body;
    const navHeight = 822;
    const scrollIkimina = 1355;
    const scrollISavings = 1932;
    const scrollPortal = 2490;
    const endScroll = 2900;
    if (scrollContainer.scrollTop > endScroll) {
      setShowBtn(false);
      setshowIkimina(false);
      setShowISavings(false);
      setShowPortal(false);
      setshowCrowdfund(false);
    } else if (scrollContainer.scrollTop > scrollPortal) {
      setShowPortal(true);
      setShowBtn(true);
      setshowIkimina(false);
      setShowISavings(false);
      setshowCrowdfund(false);
    } else if (scrollContainer.scrollTop > scrollISavings) {
      setShowISavings(true);
      setShowPortal(false);
      setshowIkimina(false);
      setshowCrowdfund(false);
    } else if (scrollContainer.scrollTop > scrollIkimina) {
      setShowISavings(false);
      setshowIkimina(true);
      setshowCrowdfund(false);
    } else if (scrollContainer.scrollTop > navHeight) {
      setshowIkimina(false);
      setShowBtn(true);
      setshowCrowdfund(true);
    } else {
      setshowCrowdfund(false);
      setShowBtn(false);
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", checkTopHeight);
    return () => {
      document.removeEventListener("scroll", checkTopHeight);
    };
  });

  return (
    <section id="products" className={styles.productsSection}>
      <div>
        <div className={styles.descriptionsContainer}>
          <div className={styles.shortLineContainer}>
            <span className={styles.shortLine}></span>
            <span className={styles.lineBullet}></span>
          </div>
          <h2 className={styles.productsTitle}>{t("productsPage.title")}</h2>
        </div>
        <p className={styles.descriptions}>
          {t("productsPage.overall_description")}
        </p>
      </div>

      {/* mobile view */}
      <div className={styles.mobileView}>
        <div className={styles.productContainer}>
          <h2 className={styles.productName}>{t("crowdfund.title")}</h2>
          <p className={styles.productDetails}>
            {t("productsPage.crowdFund_description")}
          </p>
          <img
            src={crowdFundImage}
            className={styles.productImage}
            alt="CrowdFund App Illustration"
          />
        </div>
        <div className={styles.productContainer}>
          <h2 className={styles.productName}>IKIMINA</h2>
          <p className={styles.productDetails}>
            {t("productsPage.ikimina_description")}
          </p>
          <img
            src={ikiminaImage}
            className={styles.productImage}
            alt="Ikimina App Illustration"
          />
        </div>
        <div className={styles.productContainer}>
          <h2 className={styles.productName}>Individual Savings</h2>
          <p className={styles.productDetails}>
            {t("productsPage.iSavings_description")}
          </p>
          <img
            src={iSavings}
            className={styles.productImage}
            alt="Ikimina App Illustration"
          />
        </div>
        <div className={styles.productContainer}>
          <h2 className={styles.productName}>Client Portal</h2>
          <p className={styles.productDetails}>
            {t("productsPage.clientPortal_description")}
          </p>
          <img
            src={webPortalImage}
            className={styles.lastProductImage}
            alt="Web Portal Illustration"
          />
          <a
            href="https://portal.uplus.rw"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.clientPortalLink}
          >
            {t("productsPage.clientPortal_link")} &gt;&gt;
          </a>
        </div>
      </div>

      {/* On Scroll bullet */}
      <div className={showBtn ? styles.onShowScroll : styles.onHideScroll}>
        <div className={styles.desktopLineContainer}>
          <span className={styles.scrollDesktopBullet}></span>
        </div>
      </div>

      {/* desktop view */}
      <div className={styles.desktopView}>
        {/* crowdfunding */}
        <div className={styles.productsContainer}>
          <div className={styles.productContent}>
            <div
              className={
                showCrowdfund ? "opacity-100 duration-150" : "opacity-100"
              }
            >
              <h2 className={styles.desktopProductName}>{t("crowdfund.title")}</h2>
              <p>{t("productsPage.crowdFund_description")}</p>
            </div>
          </div>
          <div className={styles.desktopLineContainer}>
            <span ref={crowdfundRef} className={styles.desktopBullet}></span>
            <span className={styles.desktopLine}></span>
          </div>
          <div className={styles.imageContainer}>
            <div
              className={
                showCrowdfund ? "opacity-100 duration-150" : "opacity-100"
              }
            >
              <img
                src={crowdFundImage}
                className={styles.desktopProductImage}
                alt="CrowdFund App Illustration"
              />
            </div>
          </div>
        </div>
        {/* ikimina */}
        <div className={styles.productsContainer}>
          <div className={styles.imageContainer}>
            <div
              className={
                showIkimina ? "opacity-100 duration-150" : "opacity-100"
              }
            >
              {" "}
              <img
                src={ikiminaImage}
                className={styles.desktopProductImage}
                alt="Ikimina App Illustration"
              />
            </div>
          </div>
          <div className={styles.desktopLineContainer}>
            <span ref={ikiminaRef} className={styles.desktopBullet}></span>
            <span className={styles.desktopLine}></span>
          </div>
          <div className={styles.productContent}>
            <div
              className={
                showIkimina ? "opacity-100 duration-150" : "opacity-100"
              }
            >
              <h2 className={styles.desktopProductName}>
                Group Savings (IKIMINA )
              </h2>
              <p>{t("productsPage.ikimina_description")}</p>
            </div>
          </div>
        </div>
        {/* iSavings */}
        <div className={styles.productsContainer}>
          <div className={styles.productContent}>
            <div
              className={
                showISavings ? "opacity-100 duration-150" : "opacity-100"
              }
            >
              <h2 className={styles.desktopProductName}>Individual Savings</h2>
              <p>{t("productsPage.iSavings_description")}</p>
            </div>
          </div>
          <div className={styles.desktopLineContainer}>
            <span ref={iSavingsRef} className={styles.desktopBullet}></span>
            <span className={styles.desktopLine}></span>
          </div>
          <div className={styles.imageContainer}>
            <div
              className={
                showISavings ? "opacity-100 duration-150" : "opacity-100"
              }
            >
              <img
                src={iSavings}
                className={styles.desktopProductImage}
                alt="CrowdFund App Illustration"
              />
            </div>
          </div>
        </div>
        {/* client portal */}
        <div className={styles.productsContainer}>
          <div className={styles.productContent}>
            <div
              className={
                showPortal ? "opacity-100 duration-150" : "opacity-100"
              }
            >
              <img
                src={webPortalImage}
                className={styles.lastProductImage}
                alt="Web Portal Illustration"
              />
              <a
                href="https://portal.uplus.rw"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.clientPortalLink}
              >
                {t("productsPage.clientPortal_link")} &gt;&gt;
              </a>
            </div>
          </div>
          <div className={styles.lastLineContainer}>
            <span ref={clientPortalRef} className={styles.desktopBullet}></span>
            <span className={styles.desktopLastLine}></span>
          </div>
          <div className={styles.imageContainer}>
            <div
              className={
                showPortal ? "opacity-100 duration-150" : "opacity-100"
              }
            >
              <h2 className={styles.desktopProductName}>Client Portal</h2>
              <p>{t("productsPage.clientPortal_description")}</p>
            </div>
          </div>
        </div>

        <div className={styles.lastLineBullet}>
          <span className={styles.desktopBullet}></span>
        </div>
      </div>
    </section>
  );
}

export default Products;
