import styles from "./styles";
import DrawLine from "../../components/lineDrawer";
import Footer from "../../pages/footer";

function PrivacyPolicy() {
  return (
    <>
      <div className=" dark:bg-defaultBlack">
        <div className={styles.container}>
          <div>{DrawLine()}</div>
          <section>
            <div>
              <h1 className={styles.title}>UPLUS APP PRIVACY POLICY</h1>
              <p className={styles.date}>
                Last modified: May 29<sup>th</sup>, 2022
              </p>
            </div>
            <article className={styles.article}>
              <h2 className={styles.articleTitle}>INTRODUCTION</h2>
              <p className={styles.articleParagraph}>
                [Uplus Mutual Partners Ltd] (“we” or “us” or “our”) respects the
                privacy of our users (“user” or “you”). This Privacy Policy
                explains how we collect, use, disclose, and safeguard your
                information when you visit our mobile application (the
                “Application”).
              </p>
              <p className={styles.articleParagraph}>
                Please read this Privacy Policy carefully.
                <span className={styles.contactText}>
                  If you do not agree with the terms of this Privacy Policy,
                  please do not access the application.
                </span>
              </p>
              <p className={styles.articleParagraph}>
                We reserve the right to make changes to this Privacy Policy at
                any time and for any reason. We will alert you about any changes
                by updating the “Last updated” date of this Privacy Policy.
              </p>
              <p className={styles.articleParagraph}>
                You are encouraged to periodically review this Privacy Policy to
                stay informed of updates. You will be deemed to have been made
                aware of, will be subject to, and will be deemed to have
                accepted the changes in any revised Privacy Policy by your
                continued use of the Application after the date such revised
                Privacy Policy is posted.
              </p>
              <p className={styles.articleParagraph}>
                This Privacy Policy does not apply to the third-party
                online/mobile store from which you install the Application or
                make payments, including any in-game virtual items, which may
                also collect and use data about you. We are not responsible for
                any of the data collected by any such third party.
              </p>
            </article>
            <article className={styles.article}>
              <h2 className={styles.articleTitle}>
                COLLECTION OF YOUR INFORMATION
              </h2>
              <div>
                <p className={styles.articleParagraph}>
                  We may collect information about you in a variety of ways. The
                  information we may collect via the Application depends on the
                  content and materials you use, and includes:
                </p>
                <div>
                  <p>
                    <strong>Personal data </strong>
                  </p>
                  <p className={styles.articleParagraph}>
                    Demographic and other personally identifiable information
                    (such as your name and email address) that you voluntarily
                    give to us when choosing to participate in various
                    activities related to the Application, such as chat, posting
                    messages in comment sections or in our forums, liking posts,
                    sending feedback, and responding to surveys. If you choose
                    to share data about yourself via your profile, online chat,
                    or other interactive areas of the Application, please be
                    advised that all data you disclose in these areas is public
                    and your data will be accessible to anyone who accesses the
                    Application.
                  </p>
                </div>
                <div>
                  <p>
                    <strong>Derivative Data</strong>
                  </p>
                  <p className={styles.articleParagraph}>
                    Information our servers automatically collect when you
                    access the Application, such as your native actions that are
                    integral to the Application, including liking, re-blogging,
                    or replying to a post, as well as other interactions with
                    the Application and other users via server log files.
                  </p>
                </div>
                <div>
                  <p>
                    <strong>Financial Data </strong>
                  </p>
                  <p className={styles.articleParagraph}>
                    Financial information, such as data related to your payment
                    method (e.g. valid credit card number, card brand,
                    expiration date) that we may collect when you purchase,
                    order, return, exchange, or request information about our
                    services from the Application. [We store only very limited,
                    if any, financial information that we collect. Otherwise,
                    all financial information is stored by our payment
                    processor, [Bank of Kigali Visa] [Authornize.Net,]
                    [Braintree Payments,] [Chargify,] [Dwolla,] [Google
                    Checkout,] [Paypal,] [SafeCharge,] [Stripe,] [WePay,]
                    [2Checkout,] [other], and you are encouraged to review their
                    privacy policy and contact them directly for responses to
                    your questions.
                  </p>
                </div>
                <div>
                  <p>
                    <strong>Facebook Permissions </strong>
                  </p>
                  <p className={styles.articleParagraph}>
                    The Application may by default access your Facebook basic
                    account information, including your name, email, gender,
                    birthday, current city, and profile picture URL, as well as
                    other information that you choose to make public. We may
                    also request access to other permissions related to your
                    account, such as friends, checkins, and likes, and you may
                    choose to grant or deny us access to each individual
                    permission. For more information regarding Facebook
                    permissions, refer to the Facebook Permissions Reference
                    page.
                  </p>
                </div>
                <div>
                  <p>
                    <strong>Data from Social Networks </strong>
                  </p>
                  <p className={styles.articleParagraph}>
                    User information from social networking sites, such as
                    [Apple's Game Center, Facebook, Google+ Instagram,
                    Pinterest, Twitter], including your name, your social
                    network username, location, gender, birth date, email
                    address, profile picture, and public data for contacts, if
                    you connect your account to such social networks. This
                    information may also include the contact information of
                    anyone you invite to use and/or join the Application.
                  </p>
                </div>
                <div>
                  <p>
                    <strong>Mobile Device Access </strong>
                  </p>
                  <p className={styles.articleParagraph}>
                    We may request access or permission to certain features from
                    your mobile device, including your mobile device's
                    [bluetooth, calendar, camera, contacts, microphone,
                    reminders, sensors, SMS messages, social media accounts,
                    storage,] and other features. If you wish to change our
                    access or permissions, you may do so in your device's
                    settings.
                  </p>
                </div>
                <div>
                  <p>
                    <strong>Mobile Device Data </strong>
                  </p>
                  <p className={styles.articleParagraph}>
                    Device information such as your mobile device ID number,
                    model, and manufacturer, version of your operating system,
                    phone number, country, location, and any other data you
                    choose to provide.
                  </p>
                </div>
                <div>
                  <p>
                    <strong>Push Notifications </strong>
                  </p>
                  <p className={styles.articleParagraph}>
                    We may request to send you push notifications regarding your
                    account or the Application. If you wish to opt-out from
                    receiving these types of communications, you may turn them
                    off in your device's settings.
                  </p>
                </div>
                <div>
                  <p>
                    <strong>Third-Party Data</strong>
                  </p>
                  <p className={styles.articleParagraph}>
                    Information from third parties, such as personal information
                    or network friends, if you connect your account to the third
                    party and grant the Application permission to access this
                    information.
                  </p>
                </div>
                <div>
                  <p>
                    <strong>Data From Contests, Giveaways, and Surveys</strong>
                  </p>
                  <p className={styles.articleParagraph}>
                    Personal and other information you may provide when entering
                    contests or giveaways and/or responding to surveys.
                  </p>
                </div>
              </div>
            </article>
            <article className={styles.article}>
              <h2 className={styles.articleTitle}>USE OF YOUR INFORMATION</h2>
              <div>
                <p className={styles.articleParagraph}>
                  Having accurate information about you permits us to provide
                  you with a smooth, efficient, and customized experience.
                  Specifically, we may use information collected about you via
                  the Application to:
                </p>
                <ul className={styles.articleList}>
                  <li>Administer sweepstakes, promotions, and contests. </li>
                  <li>Assist law enforcement and respond to subpoena.</li>
                  <li>
                    Compile anonymous statistical data and analysis for use
                    internally or with third parties.
                  </li>
                  <li>Create and manage your account.</li>
                  <li>
                    Deliver targeted advertising, coupons, newsletters, and
                    other information regarding promotions and the Application
                    to you.
                  </li>
                  <li>Email you regarding your account or order.</li>
                  <li>Enable user-to-user communications.</li>
                  <li>
                    Fulfill and manage purchases, orders, payments, and other
                    transactions related to the Application.
                  </li>
                  <li>
                    Generate a personal profile about you to make future visits
                    to the Application more personalized.
                  </li>
                  <li>
                    Increase the efficiency and operation of the Application.
                  </li>
                  <li>
                    Monitor and analyze usage and trends to improve your
                    experience with the Application.
                  </li>
                  <li>Notify you of updates to the Application.</li>
                  <li>
                    Offer new products, services, mobile applications, and/or
                    recommendations to you.
                  </li>
                  <li>Perform other business activities as needed.</li>
                  <li>
                    Prevent fraudulent transactions, monitor against theft, and
                    protect against criminal activity.
                  </li>
                  <li>Process payments and refunds.</li>
                  <li>
                    Request feedback and contact you about your use of the
                    Application.
                  </li>
                  <li> Resolve disputes and troubleshoot problems.</li>
                  <li>Respond to product and customer service requests.</li>
                  <li>Send you a newsletter.</li>
                  <li> Solicit support for the Application.</li>
                  <li>[Other]</li>
                </ul>
              </div>
            </article>
            <article className={styles.article}>
              <h2 className={styles.articleTitle}>
                DISCLOSURE OF YOUR INFORMATION
              </h2>
              <div>
                <p className={styles.articleParagraph}>
                  We may share information we have collected about you in
                  certain situations. Your information may be disclosed as
                  follows:
                </p>
                <div>
                  <p>
                    <strong>By Law or to Protect Rights </strong>
                  </p>
                  <p className={styles.articleParagraph}>
                    If we believe the release of information about you is
                    necessary to respond to legal process, to investigate or
                    remedy potential violations of our policies, or to protect
                    the rights, property, and safety of others, we may share
                    your information as permitted or required by any applicable
                    law, rule, or regulation. This includes exchanging
                    information with other entities for fraud protection and
                    credit risk reduction.
                  </p>
                </div>
                <div>
                  <p>
                    <strong>Third-Party Service Providers </strong>
                  </p>
                  <p className={styles.articleParagraph}>
                    We may share your information with third parties that
                    perform services for us or on our behalf, including payment
                    processing, data analysis, email delivery, hosting services,
                    customer service, and marketing assistance.
                  </p>
                </div>
                <div>
                  <p>
                    <strong>Marketing Communications</strong>
                  </p>
                  <p className={styles.articleParagraph}>
                    With your consent, or with an opportunity for you to
                    withdraw consent, we may share your information with third
                    parties for marketing purposes, as permitted by law.
                  </p>
                </div>
                <div>
                  <p>
                    <strong>Interactions with Other Users </strong>
                  </p>
                  <p className={styles.articleParagraph}>
                    If you interact with other users of the Application, those
                    users may see your name, profile photo, and descriptions of
                    your activity, including sending invitations to other users,
                    chatting with other users, liking posts, following blogs.
                  </p>
                </div>
                <div>
                  <p>
                    <strong>Online Postings </strong>
                  </p>
                  <p className={styles.articleParagraph}>
                    When you post comments, contributions or other content to
                    the Applications, your posts may be viewed by all users and
                    may be publicly distributed outside the Application in
                    perpetuity
                  </p>
                </div>
                <div>
                  <p>
                    <strong>Third-Party Advertisers </strong>
                  </p>
                  <p className={styles.articleParagraph}>
                    We may use third-party advertising companies to serve ads
                    when you visit the Application. These companies may use
                    information about your visits to the Application and other
                    websites that are contained in web cookies in order to
                    provide advertisements about goods and services of interest
                    to you.
                  </p>
                </div>
                <div>
                  <p>
                    <strong>Affiliates</strong>
                  </p>
                  <p className={styles.articleParagraph}>
                    We may share your information with our affiliates, in which
                    case we will require those affiliates to honor this Privacy
                    Policy. Affiliates include our parent company and any
                    subsidiaries, joint venture partners or other companies that
                    we control or that are under common control with us.
                  </p>
                </div>
                <div>
                  <p>
                    <strong>Business Partners </strong>
                  </p>
                  <p className={styles.articleParagraph}>
                    We may share your information with our business partners to
                    offer you certain products, services or promotions.
                  </p>
                </div>
                <div>
                  <p>
                    <strong>Offer Wall </strong>
                  </p>
                  <p className={styles.articleParagraph}>
                    The Application may display a third-party hosted “offer
                    wall.” Such an offer wall allows third-party advertisers to
                    offer virtual currency, gifts, or other items to users in
                    return for acceptance and completion of an advertisement
                    offer. Such an offer wall may appear in the Application and
                    be displayed to you based on certain data, such as your
                    geographic area or demographic information. When you click
                    on an offer wall, you will leave the Application. A unique
                    identifier, such as your user ID, will be shared with the
                    offer wall provider in order to prevent fraud and properly
                    credit your account.
                  </p>
                </div>
                <div>
                  <p>
                    <strong>Social Media Contacts </strong>
                  </p>
                  <p className={styles.articleParagraph}>
                    If you connect to the Application through a social network,
                    your contacts on the social network will see your name,
                    profile photo, and descriptions of your activity.
                  </p>
                </div>
                <div>
                  <p>
                    <strong>Other Third Parties </strong>
                  </p>
                  <p className={styles.articleParagraph}>
                    We may share your information with advertisers and investors
                    for the purpose of conducting general business analysis. We
                    may also share your information with such third parties for
                    marketing purposes, as permitted by law.
                  </p>
                </div>
                <div>
                  <p>
                    <strong>Sale or Bankruptcy </strong>
                  </p>
                  <p className={styles.articleParagraph}>
                    If we reorganize or sell all or a portion of our assets,
                    undergo a merger, or are acquired by another entity, we may
                    transfer your information to the successor entity. If we go
                    out of business or enter bankruptcy, your information would
                    be an asset transferred or acquired by a third party. You
                    acknowledge that such transfers may occur and that the
                    transferee may decline honor commitments we made in this
                    Privacy Policy.
                  </p>
                  <p className={styles.articleParagraph}>
                    We are not responsible for the actions of third parties with
                    whom you share personal or sensitive data, and we have no
                    authority to manage or control third-party solicitations. If
                    you no longer wish to receive correspondence, emails or
                    other communications from third parties, you are responsible
                    for contacting the third party directly.
                  </p>
                </div>
              </div>
            </article>
            <article className={styles.article}>
              <h2 className={styles.articleTitle}>TRACKING TECHNOLOGIES</h2>
              <div>
                <div>
                  <p>
                    <strong>Cookies and Web Beacons</strong>
                  </p>
                  <p className={styles.articleParagraph}>
                    [We may use cookies, web beacons, tracking pixels, and other
                    tracking technologies on the Application to help customize
                    the Application and improve your experience. When you access
                    the Application, your personal information is not collected
                    through the use of tracking technology. Most browsers are
                    set to accept cookies by default. You can remove or reject
                    cookies, but be aware that such action could affect the
                    availability and functionality of the Application. You may
                    not decline web beacons. However, they can be rendered
                    ineffective by declining all cookies or by modifying your
                    web browser's settings to notify you each time a cookie is
                    tendered, permitting you to accept or decline cookies on an
                    individual basis.]
                  </p>
                  <p className={styles.articleParagraph}>
                    [We may use cookies, web beacons, tracking pixels, and other
                    tracking technologies on the Application to help customize
                    the Application and improve your experience. For more
                    information on how we use cookies, please refer to our
                    Cookie Policy posted on the Application, which is
                    incorporated into this Privacy Policy. By using the
                    Application, you agree to be bound by our Cookie Policy.]
                  </p>
                </div>
                <div>
                  <p>
                    <strong>Internet-Based Advertising </strong>
                  </p>
                  <p className={styles.articleParagraph}>
                    Additionally, we may use third-party software to serve ads
                    on the Application, implement email marketing campaigns, and
                    manage other interactive marketing initiatives. This
                    third-party software may use cookies or similar tracking
                    technology to help manage and optimize your online
                    experience with us. For more information about opting-out of
                    interest-based ads, visit the Network Advertising Initiative
                    Opt-Out Tool or Digital Advertising Alliance Opt-Out Tool.
                  </p>
                </div>
                <div>
                  <p>
                    <strong>Website Analytics </strong>
                  </p>
                  <p className={styles.articleParagraph}>
                    We may also partner with selected third-party vendors[, such
                    as [Adobe Analytics,] [ Clicktale, ] [ Clicky ,] [ Cloudfare
                    ,] [Crazy Egg,] [Flurry Analytics,] [Google Analytics,]
                    [Heap Analytics,] [ Inspectlet, ] [ Kissmetrics,] [
                    Mixpanel, ] [ Piwik, ] and others], to allow tracking
                    technologies and remarketing services on the Application
                    through the use of first party cookies and third-party
                    cookies, to, among other things, analyze and track users'
                    use of the Application, determine the popularity of certain
                    content, and better understand online activity. By accessing
                    the Application, you consent to the collection and use of
                    your information by these third-party vendors. You are
                    encouraged to review their privacy policy and contact them
                    directly for responses to your questions. We do not transfer
                    personal information to these third-party vendors. However,
                    if you do not want any information to be collected and used
                    by tracking technologies, you can visit the third-party
                    vendor or the Network Advertising Initiative Opt-Out Tool or
                    Digital Advertising Alliance Opt-Out Tool.
                  </p>
                  <p className={styles.articleParagraph}>
                    You should be aware that getting a new computer, installing
                    a new browser, upgrading an existing browser, or erasing or
                    otherwise altering your browser's cookies files may also
                    clear certain opt-out cookies, plug-ins, or settings.
                  </p>
                </div>
              </div>
            </article>
            <article className={styles.article}>
              <h2 className={styles.articleTitle}>THIRD-PARTY WEBSITES</h2>
              <p className={styles.articleParagraph}>
                The Application may contain links to third-party websites and
                applications of interest, including advertisements and external
                services, that are not affiliated with us. Once you have used
                these links to leave the Application, any information you
                provide to these third parties is not covered by this Privacy
                Policy, and we cannot guarantee the safety and privacy of your
                information. Before visiting and providing any information to
                any third-party websites, you should inform yourself of the
                privacy policies and practices (if any) of the third party
                responsible for that website, and should take those steps
                necessary to, in your discretion, protect the privacy of your
                information. We are not responsible for the content or privacy
                and security practices and policies of any third parties,
                including other sites, services or applications that may be
                linked to or from the Application.
              </p>
            </article>
            <article className={styles.article}>
              <h2 className={styles.articleTitle}>
                SECURITY OF YOUR INFORMATION
              </h2>
              <p className={styles.articleParagraph}>
                We use administrative, technical, and physical security measures
                to help protect your personal information. While we have taken
                reasonable steps to secure the personal information you provide
                to us, please be aware that despite our efforts, no security
                measures are perfect or impenetrable, and no method of data
                transmission can be guaranteed against any interception or other
                type of misuse. Any information disclosed online is vulnerable
                to interception and misuse by unauthorized parties. Therefore,
                we cannot guarantee complete security if you provide personal
                information.
              </p>
            </article>
            <article className={styles.article}>
              <h2 className={styles.articleTitle}>POLICY FOR CHILDREN</h2>
              <p className={styles.articleParagraph}>
                We do not knowingly solicit information from or market to
                children under the age of 13. If you become aware of any data we
                have collected from children under age 13, please contact us
                using the contact information provided below.
              </p>
            </article>
            <article className={styles.article}>
              <h2 className={styles.articleTitle}>
                CONTROLS FOR DO-NOT-TRACK FEATURES
              </h2>
              <p className={styles.articleParagraph}>
                Most web browsers and some mobile operating systems and mobile
                applications include a Do-Not-Track (“DNT”) feature or setting
                you can activate to signal your privacy preference not to have
                data about your online browsing activities monitored and
                collected. No uniform technology standard for recognizing and
                implementing DNT signals has been finalized. As such, we do not
                currently respond to DNT browser signals or any other mechanism
                that automatically communicates your choice not to be tracked
                online. If a standard for online tracking is adopted that we
                must follow in the future, we will inform you about that
                practice in a revised version of this Privacy Policy.
              </p>
            </article>
            <article className={styles.article}>
              <h2 className={styles.articleTitle}>
                OPTIONS REGARDING YOUR INFORMATION
              </h2>
              <p className={styles.articleParagraph}>
                We serve you tailored advertisements both on and off of our
                Services. We target advertisements to our customer both on and
                off of our Services through a variety of ad networks and
                exchanges, using data from advertising technologies on and off
                of our service and information from advertising partners,
                publishers and data aggregators.
              </p>
            </article>
            <article className={styles.article}>
              <h2 className={styles.articleTitle}>
                OPTIONS REGARDING YOUR INFORMATION
              </h2>
              <div>
                <p>
                  <strong>[Account Information]</strong>
                </p>
                <p className={styles.articleParagraph}>
                  You agree not to use the App in any way that:
                </p>
                <ul
                  className={`${styles.articleParagraph} ${styles.articleList}`}
                >
                  <li>
                    Logging into your account settings and updating your account
                  </li>
                  <li>
                    Contacting us using the contact information provided below
                  </li>
                  <li>[Other]</li>
                </ul>
                <p className={styles.articleParagraph}>
                  Upon your request to terminate your account, we will
                  deactivate or delete your account and information from our
                  active databases. However, some information may be retained in
                  our files to prevent fraud, troubleshoot problems, assist with
                  any investigations, enforce our Terms of Use and/or comply
                  with legal requirements.
                </p>
              </div>
              <div>
                <p>
                  <strong>Emails and Communications</strong>
                </p>
                <p className={styles.articleParagraph}>
                  If you no longer wish to receive correspondence, emails, or
                  other communications from us, you may opt-out by:
                </p>
                <ul
                  className={`${styles.articleParagraph} ${styles.articleList}`}
                >
                  <li>
                    Noting your preferences at the time you register your
                    account with the Application
                  </li>
                  <li>
                    Logging into your account settings and updating your
                    preferences
                  </li>
                  <li>
                    Contacting us using the contact information provided below
                  </li>
                </ul>
                <p className={styles.articleParagraph}>
                  If you no longer wish to receive correspondence, emails, or
                  other communications from third parties, you are responsible
                  for contacting the third party directly.
                </p>
              </div>
            </article>
            <article className={styles.article}>
              <h2 className={styles.articleTitle}>CONTACT US</h2>
              <p className={styles.articleParagraph}>
                If you have questions or comments about this Privacy Policy,
                please contact us at:
              </p>
              <div>
                <p className={styles.contactText}> Uplus Mutual Partner </p>
                <p className={styles.contactText}>Kigali, RWANDA</p>
                <p className={styles.contactText}>+250 787 496 316</p>
                <p className={styles.contactText}>info@uplus.rw</p>
              </div>
            </article>
          </section>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default PrivacyPolicy;
