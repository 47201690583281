import PersonalAccount from "./personalAccount";
import GroupCreation from "./groupCreation";
import GroupJoining from "./groupJoining";
import GroupEdit from "./groupEdit";
import GroupExit from "./groupExit";
import AddMembers from "./addMembers";
import Contribute from "./contribute";
import Withdraw from "./withDraw";
import ViewWthdraw from "./viewWithdraw";
import GroupView from "./groupViews";
import TransactionsView from "./transactions";
import ShareUSSD from "./shareUSSD";
import ExportTransaction from "./exportTransaction";
import ExportViewPledges from "./viewPledges";
const crowdfunding = {
  PersonalAccount,
  GroupCreation,
  GroupJoining,
  GroupEdit,
  GroupExit,
  AddMembers,
  Contribute,
  Withdraw,
  ViewWthdraw,
  GroupView,
  TransactionsView,
  ShareUSSD,
  ExportTransaction,
  ExportViewPledges,
};
export default crowdfunding;
