const styles = {
  container:
    "bg-white mx-auto max-w-screen-2xl dark:bg-defaultBlack dark:text-white flex px-8 gap-4 py-12 text-sm md:text-base",
  title:
    "text-defaultGreen dark:text-secondaryGreen text-xl md:text-3xl font-bold",
  date: " pl-4 my-2",
  article: "my-6",
  articleTitle: "text-lg md:text-xl font-bold my-4",
  articleParagraph: "mb-4",
  articleList: "list-decimal list-inside pl-4",
  contactTitle: "mt-8 mb-4",
  contactText: "font-semibold",
};

export default styles;
