import NavBar from '../components/navbar'
import FloatingBtn from '../components/floatingBtn'
import { Outlet } from 'react-router-dom'

function RootLayout() {
  return (
    <>
      <NavBar />
      <Outlet />
      <FloatingBtn />
    </>
  )
}

export default RootLayout
