import CreateGroup from './createGroup'
import AcceptInvite from './acceptInvite'
import AddMembers from './addMembers'
import EditGroup from './editGroup'
import ExitGroup from './exitGroup'
import Contribute from './contribute'
import RequestLoan from './requestLoan'
import Payback from './payback'
import Penalties from './penalties'
import Withdraw from './withdraw'
import ViewRequest from './viewRequest'
import ChangeRules from './changeRules'
import ViewGroups from './viewGroups'
import ViewTransactions from './viewTransactions'
import ExportTransaction from './exportTransaction'

const ikimina = {
  CreateGroup,
  AcceptInvite,
  AddMembers,
  EditGroup,
  ExitGroup,
  Contribute,
  RequestLoan,
  Payback,
  Penalties,
  Withdraw,
  ViewRequest,
  ChangeRules,
  ViewGroups,
  ViewTransactions,
  ExportTransaction,
}

export default ikimina
