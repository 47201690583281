import uplusIcon from '../../../assets/uplusIcon.png'
import { useTranslation } from 'react-i18next'
import FeedBack from '../../../components/feedback'

function AccountCreation() {
  const { t } = useTranslation()

  return (
    <article>
      <h1 className='text-xl md:text-2xl font-bold dark:text-secondaryGreen'>
        {t('helpCenter.sidebar.How do I create an account on Uplus?')}
      </h1>
      <div className='w-full h-[1px] bg-[#eff2f5] dark:bg-[#303032] mt-4 '></div>
      <section className='mt-8'>
        <p className='mb-2'>
          <span className='font-semibold'>Step 1: </span>
          {t('helpCenter.General.createAccount.step1.part1')}{' '}
          <a
            href='https://apple.co/2KnmQCE'
            className='text-defaultGreen dark:text-secondaryGreen'
            target='_blank'
            rel='noreferrer'
          >
            App Store
          </a>{' '}
          (iPhone) {t('helpCenter.General.createAccount.step1.part2')}{' '}
          <a
            href='http://bit.ly/uplusRW'
            className='text-defaultGreen dark:text-secondaryGreen'
            target='_blank'
            rel='noreferrer'
          >
            Google Play Store
          </a>{' '}
          (Android).
        </p>
        <p className='mb-2'>
          <span className='font-semibold'>Step 2: </span>
          {t('helpCenter.General.createAccount.step2.part1')}{' '}
          <img src={uplusIcon} alt='uplus logo' className='inline-flex' />{' '}
          {t('helpCenter.General.createAccount.step2.part2')}
        </p>
        <p className='mb-2'>
          <span className='font-semibold'>Step 3: </span>
          {t('helpCenter.General.createAccount.step3')}
        </p>
        <p className='mb-2'>
          <span className='font-semibold'>Step 4: </span>
          {t('helpCenter.General.createAccount.step4')}
        </p>
        <p className='mb-2'>
          <span className='font-semibold'>Step 5: </span>
          {t('helpCenter.General.createAccount.step5')}
        </p>
        <p className='mb-2'>
          <span className='font-semibold'>Step 6: </span>
          {t('helpCenter.General.createAccount.step6')}
        </p>
        <p>
          <span className='font-semibold'>Step 7: </span>
          {t('helpCenter.General.createAccount.step7')}
        </p>
      </section>
      <FeedBack />
    </article>
  )
}

export default AccountCreation
