import { useAppContext } from '../../context';
import { ReactComponent as LightToggleIcon } from '../../assets/light.svg';
import { ReactComponent as DarkToggleIcon } from '../../assets/dark.svg';
import styles from './styles';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

function ThemeToggle() {
  const { isDarkMode, setIsDarkMode, setIsMenuOpen } = useAppContext();
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    const docELement = document.querySelector('body');
    if (isDarkMode) {
      docELement.classList.add('dark');
      localStorage.setItem('uplus.rw-color-theme', 'dark');
    } else {
      docELement.classList.remove('dark');
      localStorage.setItem('uplus.rw-color-theme', 'light');
    }
  }, [isDarkMode]);

  return (
    <div
      className={styles.toggleBtnContainer}
      onClick={() => {
        setIsDarkMode((isDarkMode) => !isDarkMode);
        setIsMenuOpen(false);
      }}
    >
      <button className={styles.toggleBtn}>
        <LightToggleIcon
          className={`${styles.toggleIcon} ${!isDarkMode ? styles.onIconClick : ''
            }`}
        />
        <DarkToggleIcon
          className={`${styles.toggleIcon} ${isDarkMode ? styles.onIconClick : ''
            }`}
        />
        <span className={styles.scrReaderOnly}>Toggle dark mode</span>
      </button>
      <p className={`${styles.themeLabel} ${location.pathname.includes('/help-center') ? styles.darkMode : styles.lightMode}`}>
        {isDarkMode ? t('themes.lightMode') : t('themes.darkMode')}
      </p>
    </div>
  );
}

export default ThemeToggle;
