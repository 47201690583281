import { ReactComponent as CloseIcon } from '../../assets/toastClose.svg'
import { ReactComponent as CheckIcon } from '../../assets/toastCheck.svg'
import { useTranslation } from 'react-i18next'

function Toast({ isToastShown, setIsToastShown }) {
  const { t } = useTranslation()
  return (
    isToastShown && (
      <div className='flex justify-end'>
        <div
          className='flex items-center w-full max-w-xs p-4 text-gray-500 bg-white rounded-lg shadow-2xl dark:text-gray-400 dark:bg-[#000]'
          role='alert'
        >
          <div className='inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200'>
            <CheckIcon className='inline w-5 h-5 text-green-500 dark:text-green-200' />
            <span className='sr-only'>Check icon</span>
          </div>
          <div className='ml-3 text-sm font-normal'>
            {t('helpCenter.feedbackPortion.feedbackToast')}
          </div>
          <button
            type='button'
            className='ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-[#000] dark:hover:bg-gray-700'
            aria-label='Close'
            onClick={() => setIsToastShown(false)}
          >
            <span className='sr-only'>Close</span>
            <CloseIcon className='inline w-5 h-5' />
          </button>
        </div>
      </div>
    )
  )
}

export default Toast
