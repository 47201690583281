import { useTranslation } from "react-i18next";
import FeedBack from "../../../components/feedback";
import deleteGoal from "../../../assets/deleteIcon.png";

function DeleteGoal() {
  const { t } = useTranslation();
  return (
    <article>
      <h1 className="text-xl md:text-2xl font-bold dark:text-secondaryGreen">
        {t("helpCenter.sidebar.How do I edit goal?")}
      </h1>
      <div className="w-full h-[1px] bg-[#eff2f5] dark:bg-[#303032] mt-4 "></div>
      <section className="mt-8">
        <p className="mb-2">
          <span className="font-semibold">Step 1: </span>
          {t("helpCenter.ISavings.deleteGoal.step1")}
        </p>
        <p className="mb-2">
          <span className="font-semibold">Step 2: </span>
          {t("helpCenter.ISavings.deleteGoal.step2")}
        </p>
        <p className="mb-2">
          <span className="font-semibold">Step 3: </span>
          {t("helpCenter.ISavings.deleteGoal.step3")}
          <img src={deleteGoal} alt="icon" className="inline-flex w-8" />
        </p>
        <p className="mb-2">
          <span className="font-semibold">Step 4: </span>
          {t("helpCenter.ISavings.deleteGoal.step4")}
        </p>
        <p className="mb-2">
          <span className="font-semibold">Step 5: </span>
          {t("helpCenter.ISavings.deleteGoal.step5")}
        </p>
        <p className="mb-2">
          <span className="font-semibold">Note : </span>
          {t("helpCenter.ISavings.deleteGoal.note")}
        </p>
      </section>
      <FeedBack />
    </article>
  );
}

export default DeleteGoal;
