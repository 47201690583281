const styles = {
  navContainer:
    "text-sm flex justify-center items-center md:text-base bg-defaultGreen p-2 sm:px-4 w-full z-20 top-0 left-0 h-14",
  static: "static",
  fixed: "fixed",
  navItems:
    "w-full mx-auto max-w-screen-2xl flex flex-wrap justify-between items-center mx-2",
};

export default styles;
