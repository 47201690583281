import styles from './styles'
import DrawLine from '../../components/lineDrawer'
import Footer from '../../pages/footer'

function TermsAndConditions() {
  return (
    <>
      <div className=' dark:bg-defaultBlack'>
        <div className={styles.container}>
          <div>{DrawLine()}</div>
          <section>
            <div>
              <h1 className={styles.title}>Uplus Terms and Conditions</h1>
              <p className={styles.date}>
                Last modified: January 4<sup>th</sup>, 2021
              </p>
            </div>
            <article className={styles.article}>
              <h2 className={styles.articleTitle}>General Terms</h2>
              <p className={styles.articleParagraph}>
                These terms and conditions can act as a legal contract between
                you (the customers) and Uplus Mutual Partners ltd. Like any
                other terms and conditions, this agreement for Uplus app sets
                the rules and terms that ANDROID users must follow in order to
                use our app.
              </p>
              <p className={styles.articleParagraph}>
                If there is any conflict between these App Terms and the users,
                then these App Terms will prevail. We may from time to time vary
                these App Terms. Please check these App Terms regularly to
                ensure you are aware of any variations made by us. If you
                continue to use this App, you are deemed to have accepted such
                variations. If you do not agree to such variations, you should
                not use the App. By signing these terms and conditions of Uplus,
                you agree to be legally bound by same.
              </p>
            </article>
            <article className={styles.article}>
              <h2 className={styles.articleTitle}>KEY DEFINITIONS</h2>
              <div>
                <p className={styles.articleParagraph}>
                  In this agreement, the following words shall have the meanings
                  ascribed to them:
                </p>
                <p className={styles.articleParagraph}>
                  <strong>"Agreement" </strong>This means all of the legal
                  agreements of the company including a Privacy Policy, T&C,
                  Cookies Policy, Return and Refund Policy and so on.
                </p>
                <p className={styles.articleParagraph}>
                  <strong>"Account" </strong>means an account created by a User
                  on the Mobile Application as part of Registration.
                </p>
                <p className={styles.articleParagraph}>
                  <strong>"Privacy Policy" </strong>means the policy set out for
                  safety purposes of Terms and Conditions of Use.
                </p>
                <p className={styles.articleParagraph}>
                  <strong>"Register" </strong> means to create an Account on the
                  Mobile Application and "Registration" means the act of
                  creating such an Account.
                </p>
                <p className={styles.articleParagraph}>
                  <strong>"Services" </strong>means all the services provided by
                  Uplus Mutual Partners ltd via the Mobile Application to Users,
                  and "Service" means any one of them,
                </p>
                <p className={styles.articleParagraph}>
                  <strong>"Users" </strong>means users of the Mobile
                  Application, including you and "User" means any one of them.
                </p>
              </div>
            </article>
            <article className={styles.article}>
              <h2 className={styles.articleTitle}>
                RULES ABOUT USE OF THE SERVICE AND THE MOBILE APPLICATION
              </h2>
              <p className={styles.articleParagraph}>
                We will use reasonable endeavors to correct any omissions as
                soon as possible after being notified of them. However, we do
                not guarantee that the Services will be free of faults, and we
                do not accept liability for such faults, errors or omissions. In
                case of such error, fault or omission, you should report it by
                contacting us.
              </p>
              <p className={styles.articleParagraph}>
                We do not warrant that your use of the Services will be
                uninterrupted and neither do we warrant that any information (or
                messages) transmitted via the Services Application will be
                transmitted accurately, reliably, in a timely manner. We further
                do not give any warranty that the Services and the Mobile
                Application are free from viruses or anything else which may
                have a harmful effect on any technology.
              </p>
              <p className={styles.articleParagraph}>
                However, we do reserve the right to change, modify, substitute,
                suspend or remove without notice any information or Services on
                the Mobile Application from time to time. Your access to the
                Mobile Application and/or the Services may also be occasionally
                restricted to allow for repairs, maintenance or the introduction
                of new facilities or services. We will attempt to restore such
                access as soon as we reasonably can. For the avoidance of doubt,
                we reserve the right to withdraw any information or Services
                from the Mobile Application at any time. We also reserve the
                right to block access to and/or to edit or remove any material
                which in our reasonable opinion may give rise to a breach of
                these Terms and Conditions of Use.
              </p>
            </article>
            <article className={styles.article}>
              <h2 className={styles.articleTitle}>Our privacy principles</h2>
              <div>
                We will only collect, transfer, process and store your personal
                information with your express permission unless legally required
                to do so, and will only use such information for the lawful
                purpose for which it is required. We will disclose the specific
                purpose for which we use, request and store your personal
                information. We will also keep a record of that personal
                information and the specific purpose for which we collect it. We
                will not use your personal information for any other purpose,
                other than that which we disclosed to you, unless you give us
                your express consent to do so, or unless we are permitted to do
                so by law.
              </div>
            </article>
            <article className={styles.article}>
              <h2 className={styles.articleTitle}>Prohibited Uses</h2>
              <div>
                <p className={styles.articleParagraph}>
                  You agree not to use the App in any way that:
                </p>
                <ul className={styles.articleList}>
                  <li>is unlawful, illegal or unauthorized;</li>
                  <li>is defamatory of any other person;</li>
                  <li>is obscene or offensive;</li>
                  <li>
                    promotes discrimination based on race, sex, religion,
                    nationality, disability, sexual orientation or age;
                  </li>
                  <li>
                    infringes any copyright, database right or trade mark of any
                    other person;
                  </li>
                  <li>
                    is likely to harass, upset, embarrass, alarm or annoy any
                    other person;
                  </li>
                  <li>is likely to disrupt our service in any way; or</li>
                  <li>
                    Advocates, promotes or assists any unlawful act such as (by
                    way of example only) copyright infringement or computer
                    misuse.
                  </li>
                </ul>
              </div>
            </article>
            <article className={styles.article}>
              <h2 className={styles.articleTitle}>Information we collect</h2>
              <p className={styles.articleParagraph}>
                We may collect information from you when you register on our
                mobile app. We may collect your name, e mail address, phone
                number and the profile picture.
              </p>
              <p className={styles.articleParagraph}>
                We may collect and combine information when you register on our
                mobile crowd funding services including information you provide
                to us, device IDs, cookies, and other signals, including
                information obtained from third parties. We collect information
                about you when we receive it from third-parties and affiliates.
              </p>
              <p className={styles.articleParagraph}>
                We collect information from devices such as mobile phones and
                tablets about how you interact with our services and those of
                our third-party partners and information that allows us to
                recognize and associate your activity across devices and
                services. This information includes device specific identifiers
                and information such as IP address, cookie information, mobile
                device and advertising identifiers, browser version, operating
                system type and version, mobile network information, device
                settings, and software data.
              </p>
            </article>
            <article className={styles.article}>
              <h2 className={styles.articleTitle}>
                How do we use the information we collect?
              </h2>
              <p className={styles.articleParagraph}>
                Uplus Mutual Partners Ltd uses the Personal Data you provide in
                a manner that is consistent with this Privacy Policy. If you
                provide your Personal Data for a certain reason, we may use the
                Personal Data in connection with the reason for which it was
                provided. We use the information provided in the following ways.
              </p>
            </article>
            <article className={styles.article}>
              <h2 className={styles.articleTitle}>Services</h2>
              <p className={styles.articleParagraph}>
                We use your data we have collected to authenticate you and
                authorize access to our Services on the mobile app.
              </p>
            </article>
            <article className={styles.article}>
              <h2 className={styles.articleTitle}>Communication</h2>
              <p className={styles.articleParagraph}>
                We will contact you through email, short message services (SMS),
                phone call, and other ways through our Services, including text
                messages and push notifications. We will send you messages about
                the availability of our Services, security, or other
                service-related issues. We also send messages about how to use
                the Services and network updates.
              </p>
            </article>
            <article className={styles.article}>
              <h2 className={styles.articleTitle}>Advertising</h2>
              <p className={styles.articleParagraph}>
                We serve you tailored advertisements both on and off of our
                Services. We target advertisements to our customer both on and
                off of our Services through a variety of ad networks and
                exchanges, using data from advertising technologies on and off
                of our service and information from advertising partners,
                publishers and data aggregators.
              </p>
            </article>
            <article className={styles.article}>
              <h2 className={styles.articleTitle}>Marketing</h2>
              <p className={styles.articleParagraph}>
                We use data and content about our customers for invitations,
                promotions and communications solely for promoting our services.
              </p>
            </article>
            <article className={styles.article}>
              <h2 className={styles.articleTitle}>Customer Support</h2>
              <p className={styles.articleParagraph}>
                We use the data needed to investigate, respond to and resolve
                complaints and Service issues Security, Fraud and Investigations
                We use your data (including your communications) if we think
                it’s necessary for security purposes or to investigate possible
                fraud or other violations of our User Agreement or this Privacy
                Policy and/or attempts to harm our Members or Visitors.
              </p>
            </article>
            <article className={styles.article}>
              <h2 className={styles.articleTitle}>
                Who and when do we share your information?
              </h2>
              <div>
                Uplus Mutual Partners ltd is not in the business of selling your
                information. We consider this information to be a vital part of
                our relationship with you. There are, however, certain
                circumstances in which we may share your Personal Data with
                certain third parties without further notice to you, as set
                forth below:
                <p className={styles.articleParagraph}>
                  <span className={styles.contactText}>
                    Business Transfers:{" "}
                  </span>
                  As we develop our business, we might sell or buy businesses or
                  assets. In the event of a corporate sale, merger,
                  reorganization, dissolution or similar event, Personal Data
                  may be part of the transferred assets.
                </p>
                <p className={styles.articleParagraph}>
                  <span className={styles.contactText}>
                    Related Companies:{" "}
                  </span>
                  We may also share your Personal Data with our Related
                  Companies for purposes consistent with this Privacy Policy.
                </p>
                <p className={styles.articleParagraph}>
                  <span className={styles.contactText}>
                    Agents, Consultants and Related Third Parties:{" "}
                  </span>
                  Like many businesses, Uplus Mutual Partners ltd, sometimes
                  hires other companies to perform certain business-related
                  functions. Examples of such functions include mailing
                  information, maintaining databases and processing payments.
                  When we employ another entity to perform a function of this
                  nature, we only provide them with the information that they
                  need to perform their specific function.
                </p>
                <p className={styles.articleParagraph}>
                  <span className={styles.contactText}>
                    Legal Requirements:
                  </span>
                  Uplus Mutual Partners ltd may disclose your Personal Data if
                  required to do so by law or in the good faith belief that such
                  action is necessary to (i) comply with a legal obligation,
                  (ii) protect and defend the rights or property of Uplus Mutual
                  Partners ltd, (iii) act in urgent circumstances to protect the
                  personal safety of users of the Services or the public, or
                  (iv) protect against legal liability.
                </p>
              </div>
            </article>
            <article className={styles.article}>
              <h2 className={styles.articleTitle}>
                Security of your information
              </h2>
              <p className={styles.articleParagraph}>
                Uplus Mutual Partners ltd takes reasonable steps to protect the
                Personal Data provided via the Services from loss, misuse, and
                unauthorized access, disclosure, alteration, or destruction.
                However, no Internet or email transmission is ever fully secure
                or error free. In particular, email sent to or from the Services
                may not be secure. Therefore, you should take special care in
                deciding what information you send to us via email. Please keep
                this in mind when disclosing any Personal Data to Uplus Mutual
                Partners ltd via the Internet.
              </p>
            </article>
            <article className={styles.article}>
              <h2 className={styles.articleTitle}>
                Your liability for unauthorized instructions
              </h2>
              <p className={styles.articleParagraph}>
                You will not be responsible nor have any liability for any
                instruction that is not authorized by you but is given using
                your Security Codes if:
              </p>
              <p className={styles.articleParagraph}>
                Such instruction is given after you have notified us that you
                have discovered or suspected that your Security Codes are known
                to someone else Your Security Codes or Password has become known
                to the person giving the unauthorized instruction as a result of
                our gross negligence or willful default on our part.
              </p>
            </article>
            <article className={styles.article}>
              <h2 className={styles.articleTitle}>Data Retention</h2>
              <p className={styles.articleParagraph}>
                We keep most of your personal data for as long as your account
                is active. We retain the personal data you provide while your
                account is in existence or as needed to provide you with our
                services. Rights to Access and Control Your Personal Data You
                can access your personal data from our services when you follow
                our procedures on data subject requests. You can always modify
                or update your personal data using the applicable menus in the
                app.
              </p>
              <p className={styles.articleParagraph}>
                When you wish to deactivate yourself from this mobile app, you
                are required to go to the account settings and delete account.
                Uplus Mutual Partners ltd shall contact you within ten days to
                validate the request for processing. A de-activated account may
                still have transactional history kept on our systems.
              </p>
            </article>
            <article className={styles.article}>
              <h2 className={styles.articleTitle}>
                Contacting Uplus Mutual Partners ltd
              </h2>
              <p className={styles.articleParagraph}>
                To keep your Personal Data accurate, current, and complete,
                please contact us as specified below. We will take reasonable
                steps to update or correct Personal Data in our possession that
                you have previously submitted via the Services.
              </p>
              <div>
                <p className={styles.contactTitle}>
                  You may contact us as follows:
                </p>
                <p className={styles.contactText}> Uplus Mutual Partner </p>
                <p className={styles.contactText}>Kigali, RWANDA</p>
                <p className={styles.contactText}>+250 787 496 316</p>
                <p className={styles.contactText}>info@uplus.rw</p>
              </div>
            </article>
          </section>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default TermsAndConditions
