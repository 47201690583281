import { createContext, useContext, useState } from 'react'
import checkTheme from '../helpers/checkThemePreferrence'
import checkLanguage from '../helpers/checkLanguage'
const AppContext = createContext()

const isDarkTheme = checkTheme()

function ContextProvider({ children }) {
  const [activeLink, setActiveLink] = useState('Home')
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isDarkMode, setIsDarkMode] = useState(isDarkTheme)
  const [language, setLanguage] = useState(checkLanguage())
  const [dropDownLinks, setDropDownLinks] = useState('')

  return (
    <AppContext.Provider
      value={{
        activeLink,
        setActiveLink,
        isMenuOpen,
        setIsMenuOpen,
        isDarkMode,
        setIsDarkMode,
        language,
        setLanguage,
        dropDownLinks,
        setDropDownLinks,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export function useAppContext() {
  return useContext(AppContext)
}
export default ContextProvider
