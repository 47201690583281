import styles from './styles';

function DrawLine() {
  const lineSections = [];
  const nbrOfSection = 4;
  for (let i = 0; i < nbrOfSection; i++) {
    lineSections.push(
      <>
        <span className={styles.desktopBullet}></span>
        <span className={styles.desktopLine}></span>
      </>
    );
    if (i === nbrOfSection - 1) {
      lineSections.push(
        <>
          <span className={styles.desktopBullet}></span>
          <span className={styles.desktopLine}></span>
          <span className={styles.desktopBullet}></span>
        </>
      );
    }
  }
  return (
    <>
      {lineSections.map((line, id) => {
        return (
          <div key={id} className={styles.desktopLineContainer}>
            {line}
          </div>
        );
      })}
    </>
  );
}
export default DrawLine;
