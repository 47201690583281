const styles = {
  footerSection: "text-sm md:text-base bg-defaultGreen text-white",
  container:
    "mx-auto max-w-screen-2xl grid grid-cols-1 sm:grid-cols-2 gap-12 py-8 px-6 md:grid-cols-4",
  sectionContainer: " md:bg-left bg-contain bg-no-repeat",
  overviewText: "py-4",
  socialLinksContainer: "flex gap-4 my-4",
  socialIcon: "w-7 h-7",
  screeenOnly: "sr-only ",
  sectionTitle: "mb-6 text-sm font-semibold text-secondaryGreen uppercase",
  item: "mb-4",
  link: "hover:text-defaultYellow no-underline duration-150",
  lastSectionContainer:
    "pb-20 md:flex md:flex-col sm:items-center gap-3 basis-1/3 md:bg-center bg-contain bg-no-repeat",
  downloadBtnContainer: "block mb-2",
  iosBtn: "w-40 max-h-12 md:max-h-14",
  androidBtn: "w-40 max-h-14 md:max-h-16",
  languageContainer: "ml-0",
  rightsContainer: "flex justify-center items-center pb-4",
};

export default styles;
