import plusIcon from "../../../assets/plusIcon.png";
import createGroup from "../../../assets/createGroup.png";
import addUser from "../../../assets/addUser.png";
import { useTranslation } from "react-i18next";
import FeedBack from "../../../components/feedback";

function CreateGroup() {
  const { t } = useTranslation();

  return (
    <article>
      <h1 className="text-xl md:text-2xl font-bold dark:text-secondaryGreen">
        {t("helpCenter.sidebar.How do I create Ikimina group?")}
      </h1>
      <div className="w-full h-[1px] bg-[#eff2f5] dark:bg-[#303032] mt-4 "></div>
      <section className="mt-8">
        <p className="mb-2">
          <span className="font-semibold">Step 1: </span>
          {t("helpCenter.Ikimina.createGroup.step1")}
        </p>
        <p className="mb-2">
          <span className="font-semibold">Step 2: </span>
          {t("helpCenter.Ikimina.createGroup.step2.part1")}
          <img
            src={createGroup}
            alt="update email icon"
            className="inline-flex h-20 w-20"
          />{" "}
          {t("helpCenter.Ikimina.createGroup.step2.part2")}
          <img
            src={plusIcon}
            alt="update email icon"
            className="inline-flex"
          />{" "}
          {t("helpCenter.Ikimina.createGroup.step2.part3")}
        </p>
        <p className="mb-2">
          <span className="font-semibold">Step 3: </span>
          {t("helpCenter.Ikimina.createGroup.step3")}
        </p>
        <p className="mb-2">
          <span className="font-semibold">Step 4: </span>
          {t("helpCenter.Ikimina.createGroup.step4.part1")} <br />
          <ul className=" list-disc pl-8">
            <li>{t("helpCenter.Ikimina.createGroup.step4.part2")}</li>
            <li>{t("helpCenter.Ikimina.createGroup.step4.part3")}</li>
            <li>{t("helpCenter.Ikimina.createGroup.step4.part4")}</li>
            <li>{t("helpCenter.Ikimina.createGroup.step4.part5")}</li>
            <li>{t("helpCenter.Ikimina.createGroup.step4.part6")}</li>
            <li>{t("helpCenter.Ikimina.createGroup.step4.part7")}</li>
            <li>{t("helpCenter.Ikimina.createGroup.step4.part8")}</li>
            <li>{t("helpCenter.Ikimina.createGroup.step4.part9")}</li>
            <li>{t("helpCenter.Ikimina.createGroup.step4.part10")}</li>
          </ul>
        </p>
        <p className="mb-2">
          <span className="font-semibold">Step 5: </span>
          {t("helpCenter.Ikimina.createGroup.step5.part1")}
        </p>
        <p className="mb-2">
          <span className="font-semibold">Step 6: </span>
          {t("helpCenter.Ikimina.createGroup.step6.part1")}{" "}
          <img
            src={addUser}
            alt="update email icon"
            className="inline-flex h-16 w-16"
          />{" "}
        </p>
        <p>
          <span className="font-semibold">Step 7: </span>
          {t("helpCenter.Ikimina.createGroup.step7.part1")}
          {t("helpCenter.Ikimina.createGroup.step7.part2")}
        </p>
      </section>
      <FeedBack />
    </article>
  );
}

export default CreateGroup;
