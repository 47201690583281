import addMember from '../../../assets/addPerticipant.png'
import check from '../../../assets/check.png'
import { useTranslation } from 'react-i18next'
import FeedBack from '../../../components/feedback'

function AddMembers() {
  const { t } = useTranslation()

  return (
    <article>
      <h1 className='text-xl md:text-2xl font-bold dark:text-secondaryGreen'>
        {t(
          'helpCenter.sidebar.How do I invite or add members in an existing Ikimina group?'
        )}
      </h1>
      <div className='w-full h-[1px] bg-[#eff2f5] dark:bg-[#303032] mt-4 '></div>
      <section className='mt-8'>
        <p className='mb-2'>
          <span className='font-semibold'>Step 1: </span>
          {t('helpCenter.Ikimina.addMembers.step1')}
        </p>
        <p className='mb-2'>
          <span className='font-semibold'>Step 2: </span>
          {t('helpCenter.Ikimina.addMembers.step2')}
          <img src={addMember} alt='icon' className='inline-flex' />.
        </p>
        <p>
          <span className='font-semibold'>Step 3: </span>
          {t('helpCenter.Ikimina.addMembers.step3.part1')}
          <img src={check} alt='icon' className='inline-flex' />
          {t('helpCenter.Ikimina.addMembers.step3.part2')}
        </p>
      </section>
      <FeedBack />
    </article>
  )
}

export default AddMembers
