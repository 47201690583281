const helpCenterContents = [
  {
    title: "General",
    subtitles: [
      "How do I create an account on Uplus?",
      "How do I Login my uplus account?",
      "How do I reset my password when forgotten?",
      "How do I add or update my email to my account?",
      "How do I update my profile image?",
      "How do I update my Username?",
      "How do I add a payment account?",
      "How do I remove a payment account?",
    ],
  },
  {
    title: "Crowdfunding",
    subtitles: [
      // "How do I create a personal account?",
      "How do I create a Crowdfunding group?",
      "How do I join a Crowdfunding group?",
      "How do I edit a Crowdfunding group?",
      "How do I exit a Crowdfunding group?",
      "How do I invite or add members in an existing group?",
      "How to contribute in a group?",
      "How to make a withdraw request in Crowdfunding group?",
      "How to view and approve a withdraw request?",
      "How do I view all group transactions?",
      "How do I view all transactions made by a specific member in the group?",
      "How to share the group USSD code to others?",
      "How Do I export Transactions?",
      "How do I view all group pledges?",
    ],
  },
  {
    title: "Ikimina",
    subtitles: [
      "How do I create Ikimina group?",
      "How do I accept an invitation of Ikimina group?",
      "How do I invite or add members in an existing Ikimina group?",
      "How do I edit Ikimina group?",
      "How do I exit Ikimina group?",
      "How do I deposit my share in a group?",
      "How do I request a loan in the group?",
      "How do I payback the loan in a group?",
      "How do I contribute penalties in Ikimina Group?",
      "How to make a withdraw request in a group?",
      "How to view and approve any request in the group?",
      "How do I change group rules?",
      "How do I view all Ikimina group transactions?",
      "How do I view all Ikimina transactions made by a specific member in the group?",
      "How Do I export Transactions?",
    ],
  },
  {
    title: "Individual Savings",
    subtitles: [
      "How do I create goal?",
      "How do I edit goal?",
      "How do I Delete goal?",
      "How do I save for a goal?",
      "How to make a withdraw of a goal?",
      "How do I view all goal transactions?",
      "How Do I export goal Transactions?",
    ],
  },
];
const helpCenterContentsRW = [
  {
    title: "General",
    subtitles: [
      "Ni gute natunga konti ya Uplus?",
      "Ni gute nakwinjira muri konti yanjye ya Uplus?",
      "Ni gute nagarura ijambo ry'ibanga mugihe naryibagiwe?",
      "Ni gute nahuza imeri kuri konti yanjye?",
      "Ni gute nashyiraho ifoto k'umwirondoro wanjye?",
      "Ni gute nashyiraho izina ryanjye?",
      "Ni gute nashyiraho uburyo bwo kwishyura?",
      "Ni gute nakuraho uburyo bwo kwishyura?",
    ],
  },
  {
    title: "Crowdfunding",
    subtitles: [
      // "Ni gute natunga konti bwite?",
      "Ni gute narema konti y'itsinda?",
      "Ni gute nakwisanga mu itsinda?",
      "Ni gute nahindura itsinda?",
      "Ni gute nava mu itsinda?",
      "Ni gute natumira cyangwa nkashyira umunyamuryango mu itsinda?",
      "Ni gute umusanzu utangwa mu itsinda?",
      "Ni gute nabikuza amafaranga mu itsinda?",
      "Ni gute nabona nkanemeza ubusabe busaba kubikuza?",
      "Ni gute nabona uko amafaranga yatanzwe?",
      "Ni gute nabona amafaranga yatanzwe n'umunyamuryango runaka mu itsinda?",
      "Ni gute nasangiza kode iranga itsinda?",
      "Ni gute nasohora lisite y'ibikorwa byakozwe?",
      "Ni gute nabona amafaranga y'imihigo?",
    ],
  },
  {
    title: "Ikimina",
    subtitles: [
      "Ni gute narema itsinda ry'ikimina?",
      "Ni gute nakwemeza ubusabe bw'ikimina?",
      "Ni gute natumira cyangwa nkashyira abanyamuryango mu itsinda?",
      "Ni gute nahindura itsinda?",
      "Ni gute nava mu itsinda?",
      "Ni gute natanga umusanzu wange mu itsinda?",
      "Ni gute nasaba inguzanyo mu itsinda?",
      "Ni gute nakwishyura ideni nafashe mu itsinda?",
      "Ni gute nakwishyura ibihano naciwe mu itsinda?",
      "Ni gute nabikuza amafaranga mu itsinda?",
      "Ni gute nareba nkanemeza ubusabe bwaje mu itsinda?",
      "Ni gute nahindura amategeko agenga itsinda?",
      "Ni gute nabona uko amafaranga yatanzwe mu itsinda?",
      "Ni gute nabona amafaranga yatanzwe n'umunyamuryango runaka?",
      "Ni gute nasohora lisite y'ibikorwa byakozwe?",
    ],
  },
  {
    title: "Individual Savings",
    subtitles: [
      "Ni gute narema intego?",
      "Ni gute nahindura intego?",
      "Ni gute nasiba intego?",
      "Ni gute nazigamira intego?",
      "Ni gute nabikuza ku intego?",
      "Ni gute nareba ibyakozwwe ku intego?",
      "Ni gute nasohora lisite yibyo nakoze ku intego?",
    ],
  },
];
export const allEnQuestions = helpCenterContents.reduce((prev, curr) => {
  return prev.concat(curr.subtitles);
}, []);

export const allRwQuestions = helpCenterContentsRW.reduce((prev, curr) => {
  return prev.concat(curr.subtitles);
}, []);

export default helpCenterContents;
