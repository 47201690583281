const styles = {
  productsSection:
    "sm:text-sm md:text-base lg:text-lg mt-8 md:mt-0 px-6 md:px-8 xl:px-6 pt-2 md:pt-8 text-black dark:text-white lg:mx-12",

  // Mobile styles
  descriptionsContainer: "flex items-center gap-4 flex-wrap",
  shortLineContainer: "flex items-center",
  shortLine: "block w-16 h-0.5 bg-defaultGreen dark:bg-secondaryGreen",
  lineBullet:
    "block w-3 h-3 rounded-full bg-defaultGreen pt-px dark:bg-secondaryGreen",
  productsTitle:
    "text-thirdGreen dark:text-secondaryGreen font-semibold text-2xl",
  descriptions: "mt-6 md:w-1/2",
  mobileView: "md:hidden",
  productContainer: " pt-8",
  productName: "text-thirdGreen dark:text-secondaryGreen font-semibold text-xl",
  productSketch: "w-2/3 sm:w-3/5 mx-auto mt-8 mb-2",
  productImage: "w-5/6 sm:w-2/3 mx-auto my-10",
  lastProductImage: " w-full mx-auto mt-10 mb-2",
  productDetails: "",
  clientPortalLink:
    "block text-center italic text-thirdGreen dark:text-secondaryGreen hover:opacity-70 dark:hover:opacity-50",

  // Desktop styles
  desktopView: "hidden md:block mt-10",
  productsContainer: "pt-8 flex justify-center gap-8",
  desktopProductName:
    "text-thirdGreen dark:text-secondaryGreen font-semibold text-xl pb-4",
  desktopProductSketch: "w-5/6 mx-auto my-10",
  imageContainer: "w-1/2 mx-auto",
  desktopProductImage: "w-5/6 mx-auto mt-8 mb-2",
  desktopProductDetails: "",
  desktopLineContainer: "flex flex-col items-center mt-3 -mb-11",
  lastLineContainer: "flex flex-col items-center mt-3",
  lastLineBullet: "flex flex-col items-center ",
  desktopBullet:
    "block w-5 h-5 relative top-0 rounded-full bg-[#607D7B] dark:bg-defaultGreen pt-px dark:bg-secondaryGreen",
  desktopLine: "block w-0.5 h-full bg-[#607D7B] dark:bg-defaultGreen",
  desktopLastLine: "block w-0.5 h-[94%] bg-[#607D7B] dark:bg-defaultGreen",
  productContent: "w-1/2",

  // scroll bullet
  scrollDesktopBullet:
    "hidden md:block fixed bottom-1/2  w-5 h-5 rounded-full bg-[#607D7B] dark:bg-defaultGreen  dark:bg-secondaryGreen",
  onShowScroll: "opacity-0",
  onHideScroll: "opacity-0",
};
export default styles;
