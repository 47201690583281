import { useEffect, useState } from 'react'
import styles from './styles'
import editIcon from '../../assets/edit.svg'
import TextField from './textField'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import Toast from '../toast'

function FeedBack() {
  const { t } = useTranslation()
  const [userAnswer, setUserAnswer] = useState('')
  const location = useLocation()
  const [feedBackRequest, setFeedBackRequest] = useState(true)
  const [isToastShown, setIsToastShown] = useState(true)

  useEffect(() => {
    if (isToastShown) {
      let id = setTimeout(() => {
        setIsToastShown(false)
      }, 5000)
      return () => clearTimeout(id)
    }
  })

  if (location.pathname === '/help-center') return null
  return (
    <section className={styles.container}>
      {feedBackRequest ? (
        <div>
          <div className={styles.lineDrawer}></div>
          <div className={styles.feedbackContent}>
            {!userAnswer ? (
              <>
                <p className={styles.feedbackHeading}>
                  <img
                    src={editIcon}
                    alt='edit-icon'
                    className={styles.feedbackIcon}
                  />{' '}
                  {t('helpCenter.feedbackPortion.title')}
                </p>
                <div className={styles.btnContainer}>
                  <button
                    className={styles.btn}
                    onClick={() => setUserAnswer('yes')}
                  >
                    {t('helpCenter.feedbackPortion.btn1')}
                  </button>
                  <button
                    className={styles.btn}
                    onClick={() => setUserAnswer('no')}
                  >
                    {t('helpCenter.feedbackPortion.btn2')}
                  </button>
                </div>
              </>
            ) : (
              <TextField
                placeholder={
                  userAnswer === 'yes'
                    ? t('helpCenter.feedbackPortion.placeholder.forYesAnswer')
                    : t('helpCenter.feedbackPortion.placeholder.forNoAnswer')
                }
                setUserAnswer={setUserAnswer}
                userAnswer={userAnswer}
                setIsToastShown={setIsToastShown}
                setFeedBackRequest={setFeedBackRequest}
              />
            )}
          </div>
          <div className={styles.lineDrawer}></div>
        </div>
      ) : (
        <Toast isToastShown={isToastShown} setIsToastShown={setIsToastShown} />
      )}
    </section>
  )
}

export default FeedBack
