import logo from '../../assets/logo.png';
import styles from './styles';

function Logo() {
  return (
    <div className={styles.container}>
      <img src={logo} className={styles.fluidImage} alt="Uplus Logo" />
    </div>
  );
}

export default Logo;
