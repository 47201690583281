const styles = {
  btn: "button",
  menuBtnContainer: "lg:hidden flex gap-6 items-center",
  menuBtn:
    "inline-flex items-center p-2 text-sm text-gray-500 rounded-full lg:hidden bg-gray-100 focus:outline-none",
  menuIcon: "w-5 h-5",
  closeIcon: "w-8 h-8",
  closeBtn: "inline-flex items-center py-3 px-4 text-sm",
  scrReaderOnly: "sr-only",
  mobileView: "lg:hidden",
  linksContainer:
    "text-sm  fixed top-0 left-0 bottom-0 w-full overflow-y-scroll duration-500 z-20",
  onMenuOpen: "translate-x-0",
  onMenuClose: "translate-x-full",
  closeBtnContainer: "text-end lg:hidden",
  mobileLinks: "flex flex-col mb-4",
  desktopView: "hidden lg:flex flex-wrap items-center",
  desktopLinks:
    "flex flex-row items-center mt-0 text-sm font-medium mr-4 lg:mr-10 gap-6 lg:gap-8",
  helpCenterTheme: "bg-white dark:bg-defaultBlack dark:text-white",
  landigPageTheme: "bg-defaultGreen",
  helpCenterText: "dark:text-white text-black",
  landingPageText: "text-white",
  link: "block bg-white p-2 rounded-full",
  search: "w-5 h-5",
};

export default styles;
